<template>
  <div class="apply-school-container">
    <el-dialog
        title="加入正式学校"
        :visible="applySchoolVisible"
        :width="'540px'"
        :before-close="handleClose">
        <div class='apply-school-content'>
            <div v-if="currentStatus == '申请加入'" class="content-container">
                <div class="input-num" v-if="schoolCodeFlag == true">
                    <div class="tip">请输入学校编号</div>
                    <div class="rule">学校编号为6位数字或字母</div>
                    <cell-input @onInputFull="onInputFull"></cell-input>
                </div>
                <div class="apply-status" v-if="schoolCodeFlag == false">
                    <div class="icon">
                        <img v-cdnimg src="/Account/images/common/school.png" alt="">
                    </div>
                    <div class="text">
                        <div class="school-name">{{schoolName}}</div>
                        <!-- <div class="status-text">申请已提交，等待审核中</div> -->
                        <!-- <div class="apply-false">申请已提交，审核未通过</div> -->
                    </div>
                </div>
            </div>
            <div v-if="currentStatus == '待审核'" class="content-container">
                <div class="apply-status">
                    <div class="icon">
                        <img v-cdnimg src="/Account/images/common/school.png" alt="">
                    </div>
                    <div class="text">
                        <div class="school-name">{{applyInfo.SchoolName}}</div>
                        <div class="status-text">申请已提交，等待审核中</div>
                        <!-- <div class="apply-false">申请已提交，审核未通过</div> -->
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleClose">取 消</el-button>
            <el-button size="small" type="primary"
            v-if="schoolCodeFlag == true && currentStatus == '申请加入'" 
            @click="codeInputSubmit">确 定</el-button>
            <el-button size="small" type="primary" 
            v-if="applyFlag == true && currentStatus == '申请加入'"
            @click="clickApply">申请加入</el-button>
            <el-button size="small" type="primary" v-if="currentStatus == '待审核'" @click="handleClose">确 定</el-button>
            <!-- <el-button size="small" type="primary" @click="dialogVisible = false">再次申请</el-button> -->
        </span>
    </el-dialog>
  </div>
</template>
<script>
import {GET_SCHOOL_BY_NUM, APPLY_JOIN_SCHOOL} from '@/http/api.js'
import CellInput from './CellInput.vue'
export default {
  components:{CellInput},
  props:['applySchoolVisible','currentStatus','applyInfo'],
  data() {
    return {
        schoolCodeFlag:true,     // 是否显示输入学校编号
        codeList:[],             // 所输入的学校编号列表
        schoolCode:'',                // 最终的学校编号
        schoolName:'',  // 学校名称
        schoolInfo:{},               // 学校信息
        applyFlag:false,             // 是否是申请状态
    }
  },
  computed: {
    
  },
  watch: {

  },
  methods: {
    handleClose(){    // 关闭窗口之前的回调
        this.$emit('closeApplySchoolDialog')    // 关闭弹窗
    },
    codeInputSubmit(){   // 输入学校编码之后确定
        console.log("点击了学校编码");
        if(this.schoolCode.length == 6){    // 学校编号达到长度要求
            let param = {
                institutionNo:this.schoolCode
            }
            GET_SCHOOL_BY_NUM(this.$qs.stringify(param)).then((res)=>{
                console.log("根据编码查询的学校信息",res);
                if(res.data.ReturnCode == 1){   // 
                    this.schoolCodeFlag = false   // 展示学校信息
                    this.schoolInfo = res.data.result[0]
                    this.schoolName = this.schoolInfo.SchoolName
                    this.applyFlag = true
                } else {
                    this.$message.error(res.data.ReturnText.split(' ')[1]);
                }
            })
        }else{
            this.$message.error('请输入完整的学校编码');
        }
    },
    onInputFull(valList){   // 输完了学校编号
        console.log("所有的单个结果列表",valList);
        this.codeList = valList
        this.schoolCode = ''  // 重置学校编号
        this.codeList.forEach((item)=>{
            this.schoolCode += item.value
        })
        console.log("最终的学校编号",this.schoolCode);
        console.log("学校编号长度",this.schoolCode.length);
    },
    clickApply(){  // 点击申请学校
        let param = {
            session:this.getSession(),
            institutionNo:this.schoolCode
        }
        APPLY_JOIN_SCHOOL((this.$qs.stringify(param))).then((res)=>{
            console.log("申请加入学校的返回值",res);
            if(res.data.ReturnCode == 1){  // 申请加入成功
                this.$message({
                    message: '申请发送成功',
                    type: 'success'
                });
                this.handleClose()
                window.location.reload()    // 申请成功之后刷新一下
            }else{   // 申请加入失败
                this.$message.error(res.data.ReturnText.split(' ')[1]);
            }
        })
    }
  },
  created() {
    
  },
};
</script>
<style lang="scss">
.apply-school-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .content-container {
        width: 100%;
    }
    .input-num{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip {
            font-size: 20px;
            color: #131414;
            margin-bottom: 4px;
        }
        .rule {
            font-size: 14px;
            color: #909399;
            margin-bottom: 25px;
        }
    }
    .apply-status {
        display: flex;
        align-items: center;
        background-color: #F5F7FA;
        border-radius: 16px;
        width: 100%;
        height: 128px;
        .icon{
            margin-right: 24px;
            margin-left: 32px;
            img{
                width: 64px;
                height: 64px;
            }
        }
        .text{
            .school-name {
                font-size: 20px;
                color: #131414;
            }
            .status-text {
                font-size: 14px;
                color: #4E82E9;
                margin-top: 8px;
            }
            .apply-false {
                font-size: 14px;
                color: #E1140A;
                margin-top: 8px;
            }
        }
    }
}
</style>
