<!-- 上传公共组件 -->
<template>
  <div class="upload">
    <el-upload
      action="#"
      :multiple="multiple"
      :show-file-list="showFileList"
      :drag="drag"
      :accept="accept"
      :before-upload="isAsync ? beforeAsyncUpload : beforeUpload"
      :on-remove="onRemove"
      :list-type="listType"
      :file-list="fileList"
      :http-request="httpRequest"
      :limit="limit"
      :on-exceed="onExceed"
      ref="upload"
      :class="['upload-demo', !showFileList ? 'hide-list' : '', className]"
    >
      <slot></slot>
    </el-upload>
  </div>
</template>
<script>
import CloudStorage from "@/assets/cloudstorage/cloud.js";

export default {
  props: {
    multiple: Boolean,
    accept: String,
    listType: String,
    limit: Number,
    fileSize: Number, // 文件 byte
    coverFile: Boolean, // 是否覆盖同名文件
    cloudType: String, // 云对象存储类型
    options: Object, // 云对象存储的 options 配置 userid/project/moduleName/cdn/bucket/point/isTemp/accessKeyId
    validFile: Function, // 校验文件函数
    className: String,
    showFileList: Boolean,
    drag: Boolean,
    isAsync: Boolean,
    formats: Array,
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      cloudUpload: null,
    };
  },
  methods: {
    //字节转化
    bytesToSize(bytes) {
      if (bytes === 0) return "0 B";
      const k = 1024;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + sizes[i];
    },
    onSuccess(result, file) {
      let currentFile = null;
      this.fileList.some((item) => {
        if (item.uid == file.uid) {
          item.url = result.url;
          item.tempFileName = result.tempFileName;
          currentFile = item;
          return true;
        }
      });

      this.$emit("onSuccess", currentFile, this.fileList, result);
    },
    onError(err, file) {
      this.$message.error("上传失败");
      this.$emit("onError", err, file);
    },
    onProgress(event, file) {
      let currentFile = null;
      this.fileList.some((item) => {
        if (item.uid == file.uid) {
          item.percentage =
            Math.floor((event.loaded / event.total) * 10000) / 100;
          currentFile = item;
          return true;
        }
      });
      this.$emit("onProgress", event, currentFile, this.fileList);
    },
    checkFile(file) {
      if (this.fileSize && file.size > this.fileSize) {
        this.$refs["upload"].clearFiles();
        this.$message.error(
          `文件过大，最大上传文件大小为${this.bytesToSize(this.fileSize)}`
        );

        return false;
      }

      if (this.formats && this.formats.length) {
        const name = file.name;
        const ext = name.slice(name.lastIndexOf(".") + 1).toLowerCase();
        if (!this.formats.includes(ext)) {
          this.$refs["upload"].clearFiles();
          this.$message.error(
            `文件格式错误，允许上传格式为: ${this.formats.join("、")}`
          );

          return false;
        }
      }

      return true;
    },
    beforeUpload(file) {
      if (!this.checkFile(file)) return false;
      if (this.validFile && this.validFile(file) === false) return false;

      this.fileList.push({
        url: null,
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
      });
    },
    beforeAsyncUpload(file) {
      if (!this.checkFile(file)) return false;
      this.validFile && this.validFile(file);

      this.fileList.push({
        url: null,
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
      });

      return false;
    },
    httpRequest(params) {
      const file = params.file;

      this.cloudUpload
        .upload2Cloud(file, {
          coverfile: this.coverFile,
          progress: (evt) => this.onProgress(evt, file),
          failed: (err) => this.onError(err, file),
        })
        .then((result) => this.onSuccess(result, file));
    },
    onExceed(files) {
      this.$message.error(`文件最多上传${this.limit}个`);
    },
    onRemove(file) {
      if(!file) return ;
      if (file && !file.url) return;

      return this.cloudUpload.deleteCloudFile(file.url).then(() => {
        this.fileList.some((item, index) => {
          if (item.uid == file.uid) {
            this.fileList.splice(index, 1);
            return true;
          }
        });

        this.$emit("onRemove", file, this.fileList);
      });
    },
    clearFiles() {
      this.fileList = [];
      this.$refs["upload"].clearFiles();
    },
  },
  created() {
    // cloudType: aws 联通云，cos 腾讯云 oss 阿里云  默认 aws 可在 base.js 里更改
    this.cloudUpload = new CloudStorage(
      Object.assign({}, this.options, { cloudType: this.cloudType })
    );
  },
};
</script>
<style scoped>
.upload,
.upload-demo {
  display: inline-block;
}

.upload-demo /deep/ .el-upload__input {
  display: none;
}

.upload-demo /deep/ .el-upload-dragger {
  width: initial;
  height: initial;
}

.hide-list /deep/ .el-upload-list {
  display: none;
}
</style>