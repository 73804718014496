<template>
<div class="account-setting">
    <div class="setting-container">
        <!-- 左侧个人信息和导航区域 -->
        <div class="container-left">
            <div class="account-info">
                <div class="account-avatar">
                    <!-- <img v-cdnimg class="avatar-img" :src="avatar"> -->
                    <img v-cdnimg class="avatar-img"  v-show="avatar == '' || avatar == null" src="/Account/images/default-user.png">
                    <!-- <img v-cdnimg class="avatar-img"  v-show="avatar == '' || avatar == null" src="/Account/images/default-user.png"> -->
                    <img v-cdnimg class="avatar-img"  v-show="avatar != '' && avatar != null" :src="avatar">
                    <!-- <img v-cdnimg class="avatar-img" v-show="isMale == true" :src="avatar">
                    <img v-cdnimg class="avatar-img" v-show="isMale == false" :src="avatar"> -->
                    <!-- <img v-cdnimg class="avatar-img" v-show="isMale == true" src="/Account/images/setting/avatar/man.png">
                    <img v-cdnimg class="avatar-img" v-show="isMale == false" src="/Account/images/setting/avatar/woman.png"> -->
                </div>
                <div class="account-name">{{accountName}}</div>
                <div class="account-role">用户身份：{{role}}</div>
                <!-- <div class="account-coin">用户财富：{{wealth}}</div> -->
            </div>
            <div class="navigation-bar">
                <div v-for="(item,index) in navigation" :key="item.name">
                    <router-link :to="{ name:item.name, query: { path: $route.query.path }}">
                        <div @click="clickTag(index)" :class="{'each-tag':true,'tag-selected':index == currentTag}">
                            <img v-cdnimg v-show="index != currentTag" :src="item.iconPath + 'default.png'" class="tag-img">
                            <img v-cdnimg v-show="index == currentTag" :src="item.iconPath + 'selected.png'" class="tag-img">
                            <span>{{item.name}}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <!-- 右侧内容区 -->
        <div class="container-right">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
    </div>
</div>
</template>

<script>

import {GET_ACCOUNT_INFO,NEW_GET_ACCOUNT_INFO} from '../../http/api'
import '../../styles/element-variables.scss';   // element-ui主题色
export default {
    data() {
        return {
            accountName:'默认',                       // 当前用户名
            role:'默认',                              // 用户角色
            wealth:0,                                // 财富值
            avatar:"/Account/images/default-user.png",                               // 头像
            navigation:[],                           // 真实的导航条内容
            tempNavigation:[
                {                        // 导航条完整的内容
                    name:'修改资料',
                    iconPath:'/Account/images/setting/navigation/modify-info/',
                    link:'/Setting/ModifyInfo'
                },
                {
                    name:'修改密码',
                    iconPath:'/Account/images/setting/navigation/modify-pwd/',
                    link:'/Setting/ModifyPwd'
                },
                {
                    name:'班级管理',
                    iconPath:'/Account/images/setting/navigation/class-management/',
                    link:'/Setting/ClassManagement'
                },
                {
                    name:'我的组织',
                    iconPath:'/Account/images/setting/navigation/my-organization/',
                    link:'/Account/Organization'
                },
                {
                    name:'任教资历',
                    iconPath:'/Account/images/setting/navigation/teach-qualification/',
                    link:'/Setting/TeachQualification'
                },
            ], 
            relationArr: [
                {
                    name: '关联备授课账号',
                    iconPath: '/Account/images/setting/navigation/realtion-account/',
                    link: '/Setting/RelationAccount'
                }
            ],
            currentTag:0,                          // 当前所点击的导航结点，默认是0 '修改资料'
            isMale:null,                           // 性别，用来区分头像的
        }
    },
    watch:{
        $route:{
            handler(newVal, oldVal){
                if(newVal.name == '修改资料'){
                    this.fetchUserInfo()  //每次点击到修改资料时加载数据
                }
            },
            immediate:true
        }
    },
    computed:{},
    methods:{
        clickTag(index){   // 点击导航tag
            this.currentTag = index
        },
        async fetchUserInfo(){   // 获取用户信息
            let param = {
                session:this.getSession(),
                page:0
            }
            // 实际上这个接口数据不全
            await NEW_GET_ACCOUNT_INFO(this.$qs.stringify(param)).then((response)=>{    // 获取个人信息,实际上这个接口数据和LoginSession2Json里面的数据都不全，所以要凑到一块
                let userInfo = response.data.result[0]
                this.$store.commit('UPDATE_ACCOUNT_INFO', userInfo)  // 用户信息存到vuex
                this.$bus.$emit('getAccountInfo',userInfo)    // 修改资料里面回显信息
                this.avatar = userInfo.avatar
                this.role = userInfo.rolename
                this.isMale = userInfo.ismale
            })
            let account = this.loadAccount()   // 获取从LoginSession2Json接口存到session里的用户信息
            // // 初始化页面是显示用户信息
            this.accountName = account.loginname
            this.wealth = account.coin
            var roleArray = ['学校管理员','区域管理员','学生','家长','区域督导',
                            '区域资源审核员','学校资源审核员','评价管理员','区域新闻管理员',
                            '系统管理员','后台客服','区域教研员','学校督导','角色权限管理员']
            if(!this.$route.query.path){
                this.relationArr = [] //客户端删除关联备授课账号页面，网页保留不变
            }
            if(roleArray.includes(this.role) || this.$store.state.platform.PlatformSystemNumber == '000040' || this.$store.state.platform.PlatformSystemNumber == '000041'){      // 联想和联想实验室个人中心（平台编号000040 || 000041）预发布（000040 || 000041）暂时只显示前两个
                if(this.role == '教师') {
                    this.navigation = [...this.tempNavigation.slice(0,2), ...this.tempNavigation.slice(-1), ...this.relationArr];
                } else {
                    this.navigation =  [...this.tempNavigation.slice(0,2), ...this.relationArr];
                }
            } else {
                this.navigation = this.tempNavigation
            }
            // 处理 我的组织
            const platformSystemNumber = this.$store.state.platform.PlatformSystemNumber;
            if (platformSystemNumber != "000040" && platformSystemNumber!= "000041") {
                const myOrz = this.tempNavigation.find(item => item.name == '我的组织');
                const hasMyOrz = this.navigation.some(item => item.name == '我的组织');
                if (!hasMyOrz) this.navigation.push(myOrz);
            }
            this.navigation.forEach((item, index) => {    // 刷新之后保持左侧高亮
                if(item.name == this.$route.name){
                    this.currentTag = index
                }
            })
        },

    },
    created(){
        this.fetchUserInfo()
    },
    mounted(){
        // 更新完头像,再保存之后再更新左侧导航栏上方的头像
        this.$bus.$on('updateSettingAvatar', (url) => {
            this.avatar = url
        })
        // 第一次从修改资料点击班级管理或者设备管理时，需要输入密码，如果输错了，则需要把导航栏还是点击原来的位置
        this.$bus.$on('updateCurrentTag', (index) => {   
            this.currentTag = index
        })
        // 如果头像原来没有值，则需要显示默认头像，性别变的时候默认头像会改变
        this.$bus.$on("changeGender",(val) => {
            if(this.avatar == null){
                this.isMale = val
            }
        })
        if(this.avatar == null){  // 在记录密码板块，刷新网页，如果没有照片，哪就根据性别替换成默认头像

        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/account-setting.scss';   // 颜色统一定义
.account-setting{
    // background-color: $bg-color1;
    background-color: #F7F8FA;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    .setting-container{
        width: 1130px;
        margin-top: 24px;
        // background-color: $bg-color2;
        background-color: #F7F8FA;
        display: flex;
        .container-left{   // 左侧个人信息和导航条区域
            width: 216px;
            height: 960px;
            margin-right: 24px;
            background-color: #F7F8FA;
            .account-info{ // 个人信息
                height: 212px;
                background-color: $bg-color2;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .account-avatar{ // 头像
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    margin-top: 32px;
                    overflow: hidden;
                    .avatar-img{
                        width: 100%;
                    }
                }
                .account-name{  // 用户姓名
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 16px;
                    line-height: 22px;
                    color: $text-color1;
                }
                .account-role{ // 用户身份
                    margin-top: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    color: $text-color2;
                }
                .account-coin{ // 用户财富
                    margin-top: 6px;
                    font-size: 14px;
                    line-height: 20px;
                    color: $text-color2;
                }
            }
            .navigation-bar{  // 导航条
                height: 732px;
                margin-top: 16px;
                border-radius: 4px;
                background-color: $bg-color2;
                display: flex;
                flex-direction: column;
                align-items: center;
                a {   // 去除router-link默认样式
                    text-decoration: none;  
                    color:#4E5969
                }   // 去除router-link默认样式
                .router-link-active{
                    text-decoration: none;
                }
                .each-tag{   // 导航条每一项
                    // background-color: aliceblue;
                    width: 184px;
                    height: 46px;
                    margin-top: 8px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    .tag-img{ // 每一项图标
                        width: 22px;
                        height: 22px;
                        margin-left: 16px;
                        margin-right: 6px;
                        vertical-align: middle;
                    }
                }
                .each-tag:nth-of-type(1){
                    margin-top: 16px;
                }
                .tag-selected{  // 标签选中时的样式
                    color: #FF7513;
                    background-color: #F7F8FA;
                }
                .each-tag:hover{  // 标签悬浮时样式
                    background-color: #F7F8FA;
                }
            }
        }
        .container-right{  // 右侧内容区域
            // background-color: #fff1e7;
            background-color: #F7F8FA;
            width: calc(100% - 240px);
        }
    }
}
</style>