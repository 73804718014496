<template>
  <div class="container">
    <div class="title">
      <div>
        <span>我的组织</span>
      </div>
      <div>
        <el-button v-if="isClient()" type="primary" size="mini" @click="goBack">
          返回
        </el-button>
      </div>
    </div>
    <el-divider class="line"></el-divider>
    <div class="content">
      <el-table
        border
        :data="tableData"
        style="width: 100%"
        class="organization-table"
      >
        <el-table-column prop="InstitutionName" label="组织名称" align="center">
        </el-table-column>
        <el-table-column prop="RoleName" label="在组织中的角色" align="center">
        </el-table-column>
        <el-table-column label="在组织中的学科" align="center">
          <template slot-scope="scope">
            <SubjectSelect
              :ref="`ref-${scope.row.Idx}`"
              :row="scope.row"
              :allSubjects="subjects"
              @initPopover="initPopover"
              @refresh="loadTableData"
            ></SubjectSelect>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { GET_ACCOUNT_SUBJECT, GET_REGIONAL_ACCOUNT_SUBJECT } from "@/http/api.js";
import SubjectSelect from "@/components/setting/MyOrganization/SubjectSelect";
export default {
  data() {
    return {
      tableData: [],
      subjects: [],
    };
  },
  components: {
    SubjectSelect,
  },
  methods: {
    goBack() {
      let query = this.$route.query;
      location.href = `${location.origin}${query.path}`;
    },
    isClient() {
      return this.$route.query.path ? true : false;
    },
    initPopover(row) {
      this.tableData.forEach((item) => {
        if (item.Idx != row.Idx) {
          this.$refs["ref-" + item.Idx].closePopOver();
        }
      });
    },
    loadTableData() {
      this.tableData = [];
      GET_ACCOUNT_SUBJECT().then((result) => {
        const data = result.data.result;
        if (this.isValidArray(data)) {
          this.tableData = data;
        }
      });
    },
    loadSubjectData() {
      GET_REGIONAL_ACCOUNT_SUBJECT().then((result) => {
        const data = result.data.result;
        if (this.isValidArray(data)) {
          this.subjects = data.slice(1);
        }
      });
    },
  },
  created() {
    this.loadTableData();
    this.loadSubjectData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/account-setting.scss"; // 颜色统一定义

.container {
  background-color: $bg-color2;
  height: 960px;
  border-radius: 4px;
  .title {
    padding-top: 24px;
    padding-left: 32px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  .line {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .content {
    padding: 32px;

    .organization-table {
      ::v-deep th {
        background-color: #f5f7fa;
        color: #606266;
        font-size: 14px;
        font-weight: 700;
      }

      ::v-deep td {
        color: #131414;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>