<template>
<div class="container">
    <div class="title">
        <div>
            <span>修改密码</span>
        </div>
        <div>
            <el-button v-if="isClient()" type="primary" size="mini" @click="goBack">返回</el-button>
        </div>
    </div>
    <el-divider class="line"></el-divider>
    <div class="content">
        <el-form :label-position="labelPosition" label-width="80px" :model="pwd">
            <el-form-item label="当前密码">
                <el-input v-model="pwd.currentPwd" class="long-input" clearable type="password" spellcheck="false"></el-input>
                <img v-cdnimg v-show="currentPwdTip" class="pwd-img" src="/Account/images/setting/navigation/modify-pwd/wrong.png" alt="">
            </el-form-item>
            <el-form-item label="新的密码">
                <el-input v-model="pwd.newPwd" class="long-input" clearable type="password" spellcheck="false"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" class="confirm-pwd">
                <el-input v-model="pwd.confirmPwd" class="long-input" @blur="verifyPwd" clearable type="password" spellcheck="false"></el-input>
                <img v-cdnimg v-show="wrongConfirmTip" class="pwd-img" src="/Account/images/setting/navigation/modify-pwd/wrong.png" alt="">
                <img v-cdnimg v-show="rightConfirmTip" class="pwd-img" src="/Account/images/setting/navigation/modify-pwd/right.png" alt="">
            </el-form-item>
            <el-form-item>
                <div class="change-container">
                    <el-button type="primary" size="small" @click="changePwd">修 改</el-button>
                    <span class="change-text" v-show="changePwdSucc">密码修改完成</span>
                </div>
            </el-form-item>
        </el-form>
    </div>
    <!-- 跳转到班级管理和设备管理时需要重新输入密码进行验证 -->
    <confirm-login
    @closeLoginDialog="closeLoginDialog"
    @cancelLoginDialog="cancelLoginDialog"
    :loginConfirmDialog="loginConfirmDialog">
    </confirm-login>
</div>
</template>

<script>
import {GET_ACCOUNT_SEED, LOGIN_ACCOUNT_JSON, SET_NEW_PASSWORD} from '@/http/api.js'
import ConfirmLogin from '@/components/setting/Widgets/ConfirmLogin.vue';
import '@/styles/element-variables.scss';   // element-ui主题色
export default {
    components:{ConfirmLogin},
    data() {
        return {
            labelPosition: 'right',         // 表单左侧名称右对齐
            pwd: {                          // 密码信息
                currentPwd:'',              // 当前密码
                newPwd:'',                  // 新的密码
                confirmPwd:'',              // 确认密码
            },
            currentPwdTip:false,            // 如果不对显示红色x图标 
            wrongConfirmTip:false,          // 如果不对显示红色x图标 
            rightConfirmTip:false,          // 如果不对显示红色x图标 
            changePwdSucc:false,            // 是否修改密码成功的提示语
            loginConfirmDialog:false,      // 验证登录的弹窗
        }
    },
    methods:{
        isClient(){
            return this.$route.query.path ? true :false
        },
        goBack(){   // 返回到指定页面
            let query = this.$route.query;
            location.href = `${location.origin}${query.path}`;
        },
        verifyPwd(){    // 验证确认密码和新的密码输入的是否是一致的
                this.wrongConfirmTip = false
                this.rightConfirmTip = false
            if(this.pwd.newPwd === '' &&  this.pwd.confirmPwd === ''){
                this.wrongConfirmTip = false
                this.rightConfirmTip = false
            }else{
                this.pwd.newPwd === this.pwd.confirmPwd ? this.rightConfirmTip = true : this.wrongConfirmTip = true  
            }
        },
        changePwd(){   // 修改密码
            this.changePwdSucc = false   // 先把成功提示语隐藏
            if(this.pwd.currentPwd == ""){
                this.$message({
                message: '请输入当前密码',
                type: 'warning'
                });
            }else if(this.pwd.newPwd == ""){
                this.$message({
                message: '请输入新的密码',
                type: 'warning'
                });
            }else if(this.pwd.confirmPwd == ""){
                this.$message({
                message: '请输入确认密码',
                type: 'warning'
                });
            }else{
                if (this.pwd.newPwd != this.pwd.confirmPwd) {
                    this.$message.error('两次输入的密码不相同');
                    return;
                }
                if(!this.checkPassword(this.pwd.newPwd) || this.pwd.newPwd.length < 8){
                    this.$message.error('大写字母、小写字母、数字、特殊符号至少包含3种，长度≥8位');
                    return
                }
                //客户端唯一标识
                var identity = sessionStorage["stamp"] || new Date().getTime();
                sessionStorage["stamp"] = identity;
                GET_ACCOUNT_SEED(this.$qs.stringify({stamp: identity})).then((response)=>{
                    var result = response.data 
                    var key = result.key;
                    var iv = result.iv;
                    var loginParam = {
                        'account': this.encrypt(this.loadAccount().loginname, key, iv),
                        'password': this.encrypt(this.pwd.currentPwd, key, iv),
                        'xStr': JSON.stringify({ stamp: identity })
                    };
                    console.log(loginParam)
                    LOGIN_ACCOUNT_JSON(this.$qs.stringify(loginParam)).then((response)=>{
                        // console.log("验证登录信息", response)
                        var result = response.data
                        if (this.isValid(result) && this.isValidArray(result.result)) {
                            this.currentPwdTip = false  // 当前密码后面不显示红色x图标
                            if(this.pwd.newPwd != this.pwd.confirmPwd){
                                this.$message.error('确认密码与新的密码不一致，请重试');
                            }else{  // 确认密码与新密码一致是调接口修改密码
                                var param = {
                                    'session':this.getSession(),
                                    'page': 0,
                                    'newpassword':this.encrypt(this.pwd.newPwd)
                                }
                             //   console.log("新密码设置信息",param)
                                SET_NEW_PASSWORD(this.$qs.stringify(param)).then((response)=>{
                             //       console.log("设置成功了",response)
                                    var result = response.data
                                    if (this.isValid(result) && result.ReturnCode > 0) { // 设置成功
                                        this.changePwdSucc = true
                                    } else {   // 设置失败
                                        this.changePwdSucc = false
                                        var errMsg = result.ReturnText;
                                        this.$message.error(errMsg);        
                                    }                                
                                })
                            }
                        } else {
                            var errmsg = result.ReturnText;
                            if (errmsg == "ErrCode:0 Password is not correct") {
                                this.currentPwdTip = true  // 当前密码不对的时候后面显示红色x图标
                                this.$message.error('当前密码不正确，请重试');
                            }
                        }
                    })
                })
            }
        },
        closeLoginDialog(){  // 关闭验证密码的弹窗（跳到班级管理和设备管理的时候会弹出）
            var confirmStatus = "device_authorized_" + this.loadAccount().userid;
            this.updateSession(confirmStatus,0)   // 更新一下状态，下次还是需要输密验证
            this.loginConfirmDialog = false
            this.$bus.$emit("updateCurrentTag",1)  // 失败的时候还是点击修改资料标签
        },
        cancelLoginDialog(){  // 取消弹窗
            var confirmStatus = "device_authorized_" + this.loadAccount().userid;
            this.updateSession(confirmStatus,0)   // 更新一下状态，下次还是需要输密验证
            this.loginConfirmDialog = false
            this.$bus.$emit("updateCurrentTag",1)  // 失败的时候还是点击修改资料标签
        },
    },
    beforeRouteLeave (to, from, next) {
        var confirmStatus = "device_authorized_" + this.loadAccount().userid;
        if(this.getSessionVal(confirmStatus) == 1){  // 有值说明之前有验证登录过
            next(true)
        }else{
            if(to.name == "班级管理" || to.name == "设备管理"){
             //   console.log("修改密码-触发了二次登录验证",to);
                let redirectUrl = location.origin + to.path        // 跳到登录页之后，登录成功之后要回来的页面
             //   console.log("要重定向的地址",redirectUrl);
                let param = {
                    redirectUrl:encodeURI(redirectUrl),
                    type:'accountCenter',
                    isSencondLogin:'true',
                    userid:this.loadAccount().userid
                }
                let validateUrl = location.protocol + '//test-account.forclass.net/Account/SignIn?' + this.$qs.stringify(param)
            //    console.log("未处理的参数",param);
             //   console.log("已经处理的参数",this.$qs.stringify(param));

                // ----------------------------------原来的二次登录逻辑------------------------------
                this.loginConfirmDialog = true
                this.$bus.$on("confirmLoginSucc",(status)=>{
                    // console.log("验证的状态",status)
                    if(status == 'success'){  // 验证成功
                        this.loginConfirmDialog = false
                        this.updateSession(confirmStatus,1)   // 更新一下状态
                        next(true)  // 验证成功之后可以放行
                    }else{   // 验证失败
                        this.$bus.$emit("updateCurrentTag",1)  // 失败的时候还是点击修改密码
                        next(false)
                    }
                })
            }else{  // 不去这俩标签不用拦截
                next(true)
            }
        }
    },
    activated(){
        // 每次切换到这个组件时，重置输入框和图标状态
        this.pwd.currentPwd = ""
        this.pwd.newPwd = ""
        this.pwd.confirmPwd = ""
        this.currentPwdTip = false           
        this.wrongConfirmTip = false         
        this.rightConfirmTip = false         
        this.changePwdSucc = false          
        this.loginConfirmDialog = false     
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    height: 960px;
    border-radius: 4px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 32px;
    }
    .content{ // 内容区
        margin-left: 32px;
        margin-top: 32px;
        width: 80%;
        .el-form-item{
            margin-bottom: 24px;
        }
        /deep/ .el-form-item__label{  // label和输入框之间的间隔
            padding-right: 16px;
            color: #A9AEB8;
        }
        /deep/ .long-input{  // 长的输入框
            width: 384px;
        }
        .confirm-pwd{ // 确认密码
            margin-bottom: 32px;
        }
        .pwd-img{  // 当前密码和确认密码后面的图标
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 16px;
        }
        .change-container{ // 修改按钮
            display: flex;
            align-items: flex-end;
            .change-text{  // 修改按钮右侧文字
                color: #2DA718;
                font-size: 12px;
                height: 32px;
                margin-left: 16px;
            }
        }
    }
}
</style>