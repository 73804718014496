<template>
<div class="container">
    <div class="content">
        <div class="button-area">
            <div class="add-group-container">
                <div class="add-group" @click="addGroup">
                    <img v-cdnimg class="add-icon" src="/Account/images/setting/navigation/class-management/add.png">
                    新增分组
                </div>
                <div class="add-group-confirm" v-show="addGroupFlag">
                    <el-input v-model="newGroupName" placeholder="请输入小组名称" size="small" width="100px"></el-input>
                    <el-button @click="addGroupConfirm" type="primary" size="mini" class="add-group-btn">确 定</el-button>
                </div>
            </div>
            <div class="right-btn">
                <div v-show="isHeadTeacher" class="remove-student" @click="removeStudent">
                    <img v-cdnimg class="add-icon" src="/Account/images/setting/navigation/class-management/student.png">
                    除名学生
                </div>
                <div v-show="!isHeadTeacher" class="sync-headteacher-group" @click="syncHeadteacherGroup">
                    <img v-cdnimg class="add-icon" src="/Account/images/setting/navigation/class-management/sync.png">
                    同步班主任分组
                </div>
            </div>
        </div>
        <!-- 可拖动 -->
        <div class="setting-group"  v-for="item in stuGroup" :key="item.Idx">
            <!-- 小组名称 -->
            <div class="group-title">
                <div class="group-name-area">
                    <div class="group-name" v-show="!item.isEdit">{{item.Name == '其他' ? '未分组' : item.Name}}</div>
                    <el-input v-model="item.Name" size="mini" v-show="item.isEdit" class="group-name-input"></el-input>
                    <img v-cdnimg class="edit-name-icon" @click="editGroupName(item)" v-show="item.Name!='其他'" src="/Account/images/setting/navigation/class-management/edit.png">
                </div>
                <div class="delete-area">
                    <img v-cdnimg class="delete-icon" v-show="item.Name!='其他'" @click="deleteGroup(item)" src="/Account/images/setting/navigation/class-management/delete.png">
                    <!-- <img v-cdnimg class="delete-icon" v-show="item.Name=='其他'" src="/Account/images/setting/navigation/class-management/student.png"> -->
                </div>
            </div>
            <!-- 学生选择区域 -->
            <div class="select-student-area">
                <draggable 
                class="option-container" 
                :move="moving"
                @end="dragEnd" 
                forceFallback="true" 
                v-model="item.ChildList" 
                group="site" 
                animation="300" 
                dragClass="dragging" 
                chosenClass="drag" 
                ghostClass="drag">
                    <!-- <div :class="{'student-option':true}" v-for="stu in item.ChildList" :key="stu.Idx" @click="clickSubjectOption(stu.Idx)">{{stu.Name}}</div> -->
                    <div :class="{'student-option':true, 'stu-clicked':isStuClicked.Idx == stu.Idx}" v-for="stu in item.ChildList" :key="stu.Idx" @click="clickSubjectOption(stu)">{{stu.Name}}</div>
                </draggable>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import '@/styles/element-variables.scss';   // element-ui主题色
import draggable from 'vuedraggable'
import {SET_CLASS_TEAM, REMOVE_CLASS_TEAM, SET_STUDENT_TEAM, CREATE_CLASS_TEAM, SYNC_HEADTEACHER_GROUP, REMOVE_STU} from '@/http/api.js'
export default {
    components:{draggable},
    props:['stuGroupList','selectedClass','isHeadTeacher'],
    data() {
        return {
            isStuClicked:{Idx:null},        // 所点击的是哪个学生下标
            isLife:false,                   // 所点击的是不是生活表现
            tabIndex:1,                     // 所点击的是哪个标签
            stuGroup:[],                    // 学生分组列表，页面上显示的小组用的这儿的数据
            originStuGroup:[],              // 没有被拖动之前的小组数据，用于查找被拖动学生原来所在组的ID
            addGroupFlag:false,             // 新增小组时是否显示右侧输入小组名
            newGroupName:'',                // 新增的小组名称
            editGroupFlag:false,            // 小组名称是否处于编辑状态
            beforeChangeGroupName:'',       // 在修改之前的小组名称
            draggedElement:{},              // 被拖动的元素
            startGroupId:'',                // 拖动的元素原来所在小组的id
            endGroupId:'',                  // 拖动的元素最终所在小组的id
        }
    },
    watch:{
        stuGroupList(){
            this.isStuClicked = {Idx:null},   // 小组信息更新的时候，重置选中学生
            this.stuGroup = JSON.parse(JSON.stringify(this.stuGroupList.slice(0,-1)))  // 组件创建时把学生的分组信息拿过来
            this.stuGroup.forEach((item)=>{
                this.$set(item,'isEdit',false)
            })
            this.originStuGroup = JSON.parse(JSON.stringify(this.stuGroup))  // 把最初的小组数据保存一份
            // console.log("删除之后的分组信息",this.stuGroup)    
        }
    },
    computed:{
    },
    methods:{
        changeClassName(){  // 更改班级名称
            this.editClassNameToggle = false
        },
        clickSubjectOption(index){  // 点击修改区域的选项
            this.isStuClicked = index
        },
        clickTab(index){  // 点击标签页
            this.tabIndex = index
            index == 2 ? this.isLife = true : this.isLife = false
        },
        editGroupName(item){  // 编辑小组名称
            if(item.isEdit == false){  // 如果是将要打开编辑小组名称之前，先把原来的小组名称保存一下
                this.beforeChangeGroupName = item.Name 
            }
            if(item.isEdit == true){
                var param = {
                    session: this.getSession(),
                    classIdx:this.selectedClass - 0,
                    page:0,
                    teamName:item.Name,
                    teamIdx:item.Idx - 0
                }
                // console.log("设置小组名称的参数",param)
                SET_CLASS_TEAM(this.$qs.stringify(param)).then((response)=>{
                    var result = this.$x2js.xml2js(response.data).ResultInfo
                    if(result.ReturnText == 'ErrCode:100 Class name is not available'){
                        this.$message.error('小组名称已存在，请重试');
                        item.Name = this.beforeChangeGroupName  // 恢复原来的名字
                    }
                    // console.log("设置小组名称！",result.ReturnText)
                })
            }
            item.isEdit == true ? item.isEdit = false : item.isEdit = true;  // 小组名称编辑状态切换
        },
        async deleteGroup(item){  // 删除小组
            // console.log("要删除的小组",item)
            var param = {
                "session": this.getSession(),
                "page": 1,
                "teamIdx": item.Idx,
            };            
            await REMOVE_CLASS_TEAM(this.$qs.stringify(param))
            var tempList = []    // 删除小组时把小组的人临时保存在这个数组
            var index = 0        // 保存要删除的小组的下标
            for(let i=0;i<this.stuGroup.length;i++){  
                if(this.stuGroup[i].Idx == item.Idx){ // 找到要删除哪一个组
                    tempList = item.ChildList
                    index = i
                }
            }
            this.stuGroup.forEach((item)=>{
                if(item.Name == '其他'){   // 其他就是未分组
                    item.ChildList = item.ChildList.concat(tempList)
                }
            })
            this.stuGroup.splice(index,1)  // 删除对应的小组
            // console.log("全部小组",this.stuGroup)
        },
        findGroupId(stuIdx,GroupList){   // 寻找某元素所在学生小组的ID
            var groupIdx = null
            GroupList.forEach((item)=>{
                item.ChildList.forEach((inner)=>{
                    if(inner.Idx == stuIdx){
                        groupIdx =  item.Idx
                    }
                })
            })
            return groupIdx // 所在小组的ID
        },
        dragEnd(){  // 用户拖拽结束时候触发，调接口保存结果
            var startGroupId = this.findGroupId(this.draggedElement.Idx,this.originStuGroup)
            var endGroupId = this.findGroupId(this.draggedElement.Idx,this.stuGroup)
            // console.log("起始所在组的ID",startGroupId)
            // console.log("最终所在组的ID",endGroupId)
            var param = {  // 起始组
                "session": this.getSession(),
                "page": 1,
                "teamIdx": startGroupId,
                "uidList": JSON.stringify([this.draggedElement.Idx]),
                "add": false,
            };
            SET_STUDENT_TEAM(this.$qs.stringify(param))   // 保存起始组数据
            param.teamIdx = endGroupId
            param.add = true
            SET_STUDENT_TEAM(this.$qs.stringify(param))   // 保存终点所在组数据
        },
        moving(target){  // 正在拖动时触发，可以获取拖动元素的数据
            this.draggedElement = target.draggedContext.element  // 被拖动元素的数据
        },
        addGroup(){  // 新增分组
            this.addGroupFlag == true ? this.addGroupFlag = false : this.addGroupFlag = true
            this.newGroupName = ''  // 每次打开的时候都置空
        },
        addGroupConfirm(){  // 新增分组确定
            var param = { 
                "session": this.getSession(), 
                "page": 1, 
                "classIdx": this.selectedClass, 
                "teamName": '' 
            };
            if(this.newGroupName == ''){
                this.$message({
                    message: '请输入小组名称',
                    type: 'warning'
                });
            }else{
                param.teamName = this.newGroupName
                // console.log("当前所有的小组",this.stuGroup)
                CREATE_CLASS_TEAM(this.$qs.stringify(param)).then((response)=>{
                    if(response.data.ReturnText == "ErrCode:100 Class name is not available"){
                        this.$message.error('小组名已存在，请重新输入！');
                        this.newGroupName = ''  // 新增小组名置空
                    }else{
                        // var newGroup = response.data.result[0]
                        // newGroup.ChildList = []
                        // this.$set(newGroup,'isEdit',false)
                        // this.originStuGroup.unshift(newGroup)
                        // this.stuGroup.unshift(newGroup)
                        this.addGroupFlag = false

                        this.$bus.$emit("updateTeachingEffect")
                        // console.log("新组数据",newGroup)
                    }
                })
            }
        },
        removeStudent(){  // 除名学生
            if(this.isStuClicked.Idx == null){  // 未选中学生
                this.$message({
                    message: '请选择学生',
                    type: 'warning'
                }); 
            }else{
                // console.log("除名了学生的信息", this.isStuClicked)
                const param = {
                    classidx:this.selectedClass,
                    page:1,
                    session:this.getSession(),
                    studentid:this.isStuClicked.Idx
                }
                // console.log("除名学生的参数",param)
                const h = this.$createElement
                this.$confirm('',{
                    title:'提示',
                    message:h('div',[
                        h('p','确认除名所选择的学生吗？'),
                        h('p','除名之后，该学生将与班级脱离关系！'),
                    ]),
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    REMOVE_STU(this.$qs.stringify(param)).then((response)=>{
                        if(response.data.ReturnCode == 1){
                            this.$bus.$emit("updateTeachingEffect")   // 除名学生之后，刷新一下小组数据
                        }else{
                            this.$message.error('除名学生失败！');
                        }
                    })
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            }
        },
        syncHeadteacherGroup(){  // 同步班主任分组
            var param = {
                'session':this.getSession(),
                'page':0,
                'classIdx':this.selectedClass
            }
            SYNC_HEADTEACHER_GROUP(this.$qs.stringify(param)).then((response)=>{
                // console.log("同步成功",response.data)
                if(response.data.ReturnCode == 1){
                    this.$bus.$emit("syncHeadteacherGroup")
                    this.$message({
                        message: '同步成功',
                        type: 'success'
                    });
                }else{
                    this.$message.error('同步失败，请稍后重试'); 
                }
            })
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    min-height: 357px;
    border-radius: 4px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        padding-right: 32px;
        font-size: 20px;
        font-weight: 600;
        .tab-container{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .tab{  // 标签页
                cursor: pointer;
                font-size: 16px;
            }
            .tab-active{  // 点击某个标签页时
                font-size: 20px;
            }
            .tip{  // 提示文字
                display: inline-block;
                width: 241px;
                height: 17px;
                font-size: 12px;
                color: #A9AEB8;
            }
        }
        .tab-line{   // 教学效果和生活表现下面的跟随条
            width: 48px;
            height: 3px;
            background-color: #FF7513;
            position: relative;
            top: 15px;
            left: 15px;
            transition: 0.3s;
        }   // 切换到生活表现时
        .tab-line-life{
            transform:translate(97px)
        }
    }
    .title span{
        margin-right: 32px;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .content{ // 内容区
        padding: 24px 32px 40px 32px;
        .button-area{  // 任教下面的 选择年级班级区域
            display: flex;
            align-items: center;
            justify-content: space-between;
            .add-group-container{
                display: flex;
                .add-group{  // 新增分组
                    display: flex;
                    width: 100px;
                    height: 30px;
                    border: 1px solid #FF7513;
                    font-size: 14px;
                    border-radius: 4px;
                    cursor: pointer;
                    align-items: center;
                    color: #FF7513;
                    background: rgba(255,117,19,0.04);
                    .add-icon{
                        width: 16px;
                        height: 16px;
                        margin-left: 12px;
                        margin-right: 4px;
                    }
                }
                .add-group-confirm{
                    display: flex;
                    margin-left: 15px;
                    .add-group-btn{
                        margin-left: 10px;
                    }
                }
            }
            .right-btn{
                display: flex;
                .remove-student{   // 除名学生
                    display: flex;
                    width: 100px;
                    // margin-right: 16px;
                    height: 30px;
                    border: 1px solid #E5E6EB;
                    font-size: 14px;
                    border-radius: 4px;
                    cursor: pointer;
                    align-items: center;
                    color: #4E5969;
                    background: #F7F8FA;
                    .add-icon{
                        width: 16px;
                        height: 16px;
                        margin-left: 12px;
                        margin-right: 4px;
                    }
                }
                .sync-headteacher-group{   // 同步班主任分组
                    display: flex;
                    width: 142px;
                    height: 30px;
                    border: 1px solid #E5E6EB;
                    font-size: 14px;
                    border-radius: 4px;
                    cursor: pointer;
                    align-items: center;
                    color: #4E5969;
                    background: #F7F8FA;
                    .add-icon{
                        width: 16px;
                        height: 16px;
                        margin-left: 12px;
                        margin-right: 4px;
                    }
                }
            }
        }
        .setting-group{  // 信息展示的区域
            margin-top: 32px;
            border-radius: 4px;
            overflow: hidden;
            min-height: 111px;
            border: 1px solid #F2F3F5;
            .group-title {  // 小组名称
                width: 100%;
                height: 35px;
                background-color: #F2F3F5;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .group-name-area{
                    display: flex;
                    margin-left: 24px;
                    align-items: center;
                    color: #1D2129;
                    font-size: 16px;
                    .group-name{
                        height: 24px;
                        line-height: 24px;
                        font-weight: 500;
                    }
                    .edit-name-icon{  // 编辑小组名称图标
                        width: 24px;
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }
                .delete-area{   // 删除图标
                    margin-right: 24px;
                    height: 24px;
                    .delete-icon{
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                }
            }
            .select-student-area{  // 修改科目选择区
                background-color: #FFFFFF;
                padding: 24px 24px 8px 24px;
                .option-container{
                    height: 100%;
                    background-color: #FFFFFF;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: baseline;
                    overflow-y: auto;
                    .student-option{  // 修改学科下面的每一项
                        width: 10%;
                        height: 32px;
                        background-color: #F2F3F5;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .student-option:not(:nth-child(8n)){  // 每一行最右面一项不显示margin-right
                        margin-right: calc(20% / 7);
                    }
                    .student-option:hover{  // 悬浮时状态
                        color: #FF7513;
                    }
                    .stu-clicked{  // 选项被点击的时候
                        background-color: #FF7513;
                        color:#FFFFFF !important
                    }
                }
            }
        }
    }
    .drag{  // 修改学科下面的每一项
        width: 10%;
        height: 32px;
        background-color: #F2F3F5;
        border-radius: 4px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>