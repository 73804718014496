import Vue from "vue";
import axios from "axios";
import AWS from 'aws-sdk';
import qs from "qs";
import { getTotalDefaultConfig, getSaveKey, getTempFileName } from "./base";
import { webapiUrl } from "../../http/env";

const _vue = Vue.prototype;

export default class AWSCOS {
    /**
     * @description: 联通云上传类
     * @param {object} [options] 联通云配置参数
     * @param {number} [options.userid] 用户id
     * @param {string} [options.project] 项目名称
     * @param {string} [options.moduleName] 模块名称
     * @param {string} [options.cdn] cdn加速地址
     * @param {string} [options.bucket] bucket
     * @param {string} [options.point] endpoint
     * @return {AWSCOS}
     */
    constructor(config) {
        const base = getTotalDefaultConfig();
        const options = Object.assign({}, base, base.aws, config);

        this.cdn = options.cdn;
        this.bucket = options.bucket;
        this.region = options.point;
        this.acl = options.acl;

        this.prefix = `${options.userid}/${options.project}/${options.moduleName}/web/`;

        this.aws = ""
        this.finshed = true;

        this.options = options;
    }

    /**
     * @description: 获取临时密钥
     * @return
     */
    onGetTemporaryKey() {
        if (this.aws) return Promise.resolve();

        const url = webapiUrl + "JPublishTools/temporary/getlttemporarykey";
        return axios.post(url, qs.stringify({ bucket: "all" }), { headers: { session: _vue.getSession() } }).then(result => {
            const res = result.data.result;
            AWS.config.endpoint = this.region;
            AWS.config.accessKeyId = res.accessKeyId;
            AWS.config.secretAccessKey = res.accessKeySecret;
            AWS.config.sessionToken = res.securityToken;
            AWS.config.update({ s3ForcePathStyle: true });
            this.aws = new AWS.S3({ apiVersion: '2006-03-01', s3DisableBodySigning: false });
        });
    }

    /**
     * @description: 联通云上传
     * @param {file} file 上传文件
     * @param {object} [options] 配置
     * @param {function} [options.progress] progress
     * @param {function} [options.failed] failed
     * @param {boolean} [options.coverfile] 是否覆盖同名文件
     * @return
     */
    async upload2Cloud(file, options = {}) {
        this.finshed = false;
        _vue.startLoading();
        
        await this.onGetTemporaryKey();

        const fileName = file.name;
        const savekey = this.prefix + getSaveKey(fileName, options.coverfile);

        return new Promise((resolve, reject) => {
            this.aws.putObject({ Body: file, Bucket: this.bucket, Key: savekey }, (err, data) => {
                this.finshed = true;
                _vue.stopLoading();

                if (err) {
                    console.log("上传失败");

                    options.failed && options.failed(err);

                    reject();
                }
                if (data) {
                    this.putObjectAcl(savekey).then(() => {
                        console.log("上传成功");

                        resolve({ url: this.replaceUrl2CDN(savekey), tempFileName: getTempFileName(savekey), fileName })
                    });
                }
            }).on('httpUploadProgress', evt => options.progress && options.progress(evt));
        });
    }

    /**
     * @description: 设置云文件读取权限
     * @param {string} savekey 云文件路径
     * @return
     */
    putObjectAcl(savekey) {
        return new Promise((resolve, reject) => {
            this.aws.putObjectAcl({ Bucket: this.bucket, Key: savekey, ACL: this.acl }, (err, data) => {
                if (err) reject(err);
                if (data) resolve(data);
            })
        });
    }

    /**
     * @description: 删除联通云文件
     * @param {string} fileName diskfilename 文件路径
     * @return
     */
    async deleteCloudFile(fileName) {
        if (!fileName || fileName.trim() == "") return Promise.reject();
        _vue.startLoading();
        await this.onGetTemporaryKey();

        return new Promise((resolve, reject) => {
            this.aws.deleteObject({ Bucket: this.bucket, Key: this.prefix + getTempFileName(fileName) }, (err, data) => {
                _vue.stopLoading();
                if (err) reject(err);
                if (data) resolve(data);
            })
        })
    }

    /**
     * @description: 联通云地址转 cdn 加速地址
     * @param {string} fileName 联通云地址
     * @return {string} cdn 地址
     */
    replaceUrl2CDN(fileName) {
        return `https://${this.cdn}/${this.prefix}${getTempFileName(fileName)}`
    }
}
