<template>
  <div class="verify-wrap" @click="updateCode">
    <img class="verify-code" :src="codeUrl" alt="" />
    <img
      v-cdnimg
      src="/Account/images/signin/updateIcon.png"
      class="update-icon"
      :class="{ 'update-animation': loading }"
      alt=""
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { VALIDATE_CAPTCHA } from "@/http/api.js";
export default {
  data() {
    return {
      stamp: "",
      codeUrl: "",
      loading: false,
    };
  },
  props: {
    verifyCode: String,
    stampName: {
      type: String,
      default: "stamp",
    },
  },
  methods: {
    async updateCode() {
      if (this.loading) return;
      this.$emit("update:verifyCode", "");

      if (this.codeUrl) {
        this.loading = true;
        setTimeout(async () => {
          this.codeUrl = await this.onGetCaptcha(this.stamp);
          this.loading = false;
        }, 700);
      } else {
        this.codeUrl = await this.onGetCaptcha(this.stamp);
      }
    },
    async checkValidateCode() {
      const param = { code: this.verifyCode, id: this.stamp };
      const { data } = await VALIDATE_CAPTCHA(param);

      if (data?.data) return true;
      this.$message.error("验证码错误!");
      this.updateCode();
      return false;
    },
  },
  created() {
    this.stamp = this.getSessionVal(this.stampName);
    if (!this.stamp) {
      this.stamp = uuidv4();
      this.updateSession(this.stampName, this.stamp);
    }

    this.updateCode();
  },
};
</script>

<style lang="scss" scoped>
.verify-wrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  .verify-code {
    width: 100px;
    height: 40px;
  }

  .update-icon {
    width: 18px;
    height: 18px;
    margin-top: 5px;
    margin-left: 2px;

    &.update-animation {
      animation: loading 0.7s ease-in-out infinite forwards;
    }
    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }
  }
}
</style>