<template>
  <div class="debounce-btn">
    <div class="get-verify-code" v-show="!loading">{{ tips }}</div>
    <div v-show="loading" class="update-code">
      <span class="countdown">{{ countdown }}</span>
      <span>秒后可重新发送</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countdown: 0,
    };
  },
  props: ["loading", "tips"],
  watch: {
    loading(newVal) {
      if (newVal) {
        this.countdown = 60;
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown <= 0) {
            clearInterval(timer);
            this.$emit("update:loading", false);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.debounce-btn {
  height: 40px;
  line-height: 40px;
  margin-left: 10px;
  user-select: none;
  .get-verify-code {
    color: #ff6a00;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
  }

  .update-code {
    font-size: 14px;
    color: #a9aeb8;
    .countdown {
      width: 18px;
      text-align: right;
      margin-right: 4px;
    }
  }
}
</style>