<template>
  <el-popover
    width="208"
    trigger="manual"
    popper-class="subject-popover"
    v-model="popover"
  >
    <div class="subject-select">
      <el-input
        size="small"
        placeholder="请输入关键字"
        suffix-icon="el-icon-search"
        v-model="input"
        clearable
        @input="search"
      >
      </el-input>
      <div class="select-wrap custom-scroll">
        <el-checkbox
          v-show="!input"
          v-model="checkAll"
          :indeterminate="halfCheck"
          @change="selectAll"
        >
          全部学科
        </el-checkbox>
        <el-checkbox-group v-model="checkedSubjects" @change="selectChange">
          <el-checkbox
            v-for="item in subjects"
            :label="item.SubjectIdx"
            :key="item.SubjectIdx"
          >
            {{ item.SubjectName }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="btn-tools">
        <el-button size="small" @click="closePopOver">取消</el-button>
        <el-button size="small" type="primary" @click="submit">
          确定
        </el-button>
      </div>
    </div>
    <div class="subject-item" slot="reference" @click="clickPopover">
      <div class="subject-txt">
        {{ getSubjectTxt() }}
      </div>
      <i :class="[popover ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
    </div>
  </el-popover>
</template>

<script>
import { SET_REGIONAL_ACCOUNT_SUBJECT } from "@/http/api.js";
export default {
  data() {
    return {
      input: "",
      popover: false,
      checkAll: false,
      subjects: [],
      checkedSubjects: [],
      halfCheck: false,
    };
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    allSubjects: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getSubjectTxt() {
      const subjects = this.row.subjects;
      if (this.isValidArray(subjects)) {
        return subjects.map((item) => item.SubjectName).join("、");
      }

      return "暂无学科";
    },
    search() {
      this.subjects = this.allSubjects.filter((item) => {
        return item.SubjectName.includes(this.input);
      });
    },
    selectAll(val) {
      this.checkedSubjects = val
        ? this.allSubjects.map((item) => item.SubjectIdx)
        : [];
      this.halfCheck = false;
    },
    selectChange() {
      const ckCount = this.checkedSubjects.length;
      this.checkAll = ckCount == this.allSubjects.length;
      this.halfCheck = ckCount > 0 && ckCount < this.allSubjects.length;
    },
    closePopOver() {
      this.popover = false;
      this.input = "";
      this.checkAll = false;
      this.subjects = [];
      this.checkedSubjects = [];
      this.halfCheck = false;
    },
    submit() {
      const params = {
        aRIdxs: JSON.stringify([this.row.ArId]),
        subjects: JSON.stringify(this.checkedSubjects),
      };
      const data = this.$qs.stringify(params);
      SET_REGIONAL_ACCOUNT_SUBJECT(data).then((result) => {
        const ReturnCode = result.data.ReturnCode;
        const ReturnText = result.data.ReturnText;
        if (ReturnCode == 1) {
          this.closePopOver();
          this.$emit("refresh");
        } else {
          this.$message.error(ReturnText || "设置学科失败");
        }
      });
    },
    // 设置选中的学科
    setCheckedSubjects() {
      const subjects = this.row.subjects;
      this.checkedSubjects = subjects.map((item) => item.SubjectIdx);
    },
    clickPopover() {
      this.popover = !this.popover;
      if (!this.popover) return this.closePopOver();

      this.$emit("initPopover", this.row);
      this.setCheckedSubjects();
      if (!this.isValidArray(this.allSubjects)) {
        this.loadSubjectList();
      } else {
        this.subjects = this.allSubjects;
        this.selectChange();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subject-select {
  width: 100%;
  min-width: 208px;
  padding: 24px;
  box-sizing: border-box;

  .select-wrap {
    margin: 24px 0;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;

    .el-checkbox {
      margin-bottom: 12px;
      color: #131414;
      font-size: 14px;
      font-weight: 400;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // .el-checkbox-group {
    //   display: flex;
    //   flex-direction: column;
    // }
  }

  .btn-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-button {
      min-width: 76px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.subject-item {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  .subject-txt {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  i {
    margin-left: 10px;
    font-size: 16px;
  }
}
</style>

<style>
.subject-popover {
  padding: 0;
}
</style>