<template>
<div class="container">
    <div class="content">
        <el-tabs v-model="tabIdx" @tab-click="tabClick">
            <el-tab-pane v-for="item in tabList" :key="item.Idx" :label="item.Name" :name="item.Idx">
                <group-tab :tabIdx="tabIdx" :selectedClass="selectedClass"></group-tab>
            </el-tab-pane>
        </el-tabs>
    </div>
</div>
</template>

<script>
import '@/styles/element-variables.scss';   // element-ui主题色
import GroupTab from '../Widgets/LifeGroupTab.vue'
import {GET_LIFE_TEAM_TYPE} from '@/http/api.js'
export default {
    components:{GroupTab},
    props:['selectedClass'],
    data() {
        return {
            tabIdx:'',
            tabList:[],  // 
        }
    },
    watch:{
    },
    methods:{
        getLifeTeamTab(){  // 获取生活组有几个标签tab
            var param = {
                session: this.getSession()
            }
            GET_LIFE_TEAM_TYPE(this.$qs.stringify(param)).then((response)=>{
                // console.log("生活组的标签信息",response.data)
                this.tabList = response.data.result // 把标签信息保存起来
                this.tabIdx = this.tabList[0].Idx
            })
        },
        tabClick(){
            //console.log("点击tab了")
            this.$bus.$emit("clickTab")
        }
    },
    activated(){
        //console.log("点击生活组了")
        // console.log("this",this)
        this.getLifeTeamTab()  // 加载tab信息
    },
    mounted(){
        this.getLifeTeamTab()  // 加载tab信息
        this.$bus.$on("updateLifeTeam",()=>{
            // console.log("班级改变了，所以标签页要变")
            this.getLifeTeamTab()  // 加载tab信息
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    min-height: 357px;
    border-radius: 4px;
    .content{ // 内容区
        padding: 24px 32px 40px 32px;
    }
}
</style>