<template>
<div class="container">
    <div class="title">
        <div>
            <span>任教资历</span>
        </div>
        <div>
            <el-button v-if="isClient()" type="primary" size="mini" @click="goBack">返回</el-button>
        </div>
    </div>
    <el-divider class="line"></el-divider>
    <div class="content">
        <!-- <el-form :label-position="labelPosition" label-width="80px" :model="pwd"> -->
        <el-form :label-position="labelPosition" label-width="80px">
            <el-form-item label="教师学历">
                <el-select v-model="degree" placeholder="选择学历">
                    <el-option
                    v-for="(item,index) in degreeList"
                    :key="index"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="教师教龄">
                <el-select v-model="teachingAge" placeholder="选择教龄">
                    <el-option
                    v-for="(item,index) in teachingAgeList"
                    :key="index"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="教师职务" class="confirm-pwd">
                <el-select v-model="jobTitle" placeholder="选择职务">
                    <el-option
                    v-for="(item,index) in jobTitleList"
                    :key="index"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <div class="change-container">
                    <el-button type="primary" size="small" @click="saveInfo">保 存</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
    <!-- 跳转到班级管理和设备管理时需要重新输入密码进行验证 -->
    <!-- <confirm-login
    @closeLoginDialog="closeLoginDialog"
    @cancelLoginDialog="cancelLoginDialog"
    :loginConfirmDialog="loginConfirmDialog">
    </confirm-login> -->
</div>
</template>

<script>
import {GET_ACCOUNT_SEED, LOGIN_ACCOUNT_JSON, SET_NEW_PASSWORD, NEW_SET_ACCOUNT_INFO, DATA_CHANGE} from '@/http/api.js'
import ConfirmLogin from '@/components/setting/Widgets/ConfirmLogin.vue';
import '@/styles/element-variables.scss';   // element-ui主题色
export default {
    components:{ConfirmLogin},
    data() {
        return {
            labelPosition: 'right',            // 表单左侧名称右对齐
            originInfo:{},                     // 刚进页面的时候，页面回显的信息
            entryDate:'',                      // 入职日期
            degree:'',                         // 学历
            jobTitle:'',                       // 职务
            teachingAge:'',                    // 教龄
            teachingAgeList:[                  // 教龄列表
                {name:'20年以上'},
                {name:'16-20年'},
                {name:'11-15年'},
                {name:'6-10年'},
                {name:'4-5年'},
                {name:'1-3年'},
                {name:'1年以下'},
            ],
            degreeList:[
                {name:'博士研究生'},
                {name:'硕士研究生'},
                {name:'本科学历'},
                {name:'专科学历'},
                {name:'中专学历'},
            ],
            jobTitleList:[
                {name:'校级领导'},
                {name:'中层干部'},
                {name:'年级主任'},
                {name:'教研组长'},
                {name:'备课组长'},
                {name:'班主任'},
            ],
            loginConfirmDialog:false,      // 验证登录的弹窗
        }
    },
    methods:{
        isClient(){
            return this.$route.query.path ? true :false
        },
        goBack(){   // 返回到指定页面
            let query = this.$route.query;
            location.href = `${location.origin}${query.path}`;
        },
        handleParam(){   // 处理参数
            let param = {
                session:this.getSession(),
                updateInfos:JSON.stringify(
                    [
                        // {Type:9,Name:this.entryDate!='' && this.entryDate.split('')[0].replaceAll('/','-') || ''},
                        {Type:9,Name:this.teachingAge!='' && this.teachingAge || ''},
                        {Type:14,Name:this.degree + ''},
                        {Type:15,Name:this.jobTitle || ''},
                    ]
                )
            }
            return param
        },
        saveChange(param){    // 调接口保存变化
            DATA_CHANGE(param).then((res)=>{
                if(this.isValid(res.data.data)){
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })    
                }
            })
        },
        handleDataChange(){    // 统计变化
            let param = {
                inputData:[]
            }
            if(this.degree != this.originInfo.Education){      // 教师学历变化
                param.inputData.push({
                    collectPointName:'教师学历_减',
                    number:this.originInfo.Education || '',
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                param.inputData.push({
                    collectPointName:'教师学历_加',
                    number:this.degree,
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                this.originInfo.Education = this.degree
            }
            if(this.teachingAge != this.originInfo.AttendedDate){      //  教师教龄变化
                param.inputData.push({
                    collectPointName:'教师教龄_减',
                    number:this.originInfo.AttendedDate || '',
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                param.inputData.push({
                    collectPointName:'教师教龄_加',
                    number:this.teachingAge,
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                this.originInfo.AttendedDate = this.teachingAge
            }
            if(this.jobTitle != this.originInfo.TeacherPosition){      // 教师职务变化
                param.inputData.push({
                    collectPointName:'教师职务_减',
                    number:this.originInfo.TeacherPosition || '',
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                param.inputData.push({
                    collectPointName:'教师职务_加',
                    number:this.jobTitle,
                    userId:this.originInfo.userid,
                    schoolId:this.originInfo.schoolid,
                })
                this.originInfo.TeacherPosition = this.jobTitle
            }
            let finalParam = Object.assign({},param)
            finalParam.inputData = param.inputData.filter((item)=>{
                return item.number != ''
            })
            if(param.inputData.length > 0){
                this.saveChange(finalParam)
            }else{
                this.$message({
                    message: '保存成功',
                    type: 'success'
                })        
            }
        },
        saveInfo(){   // 保存用户信息
            let param = this.handleParam()
            if(param != undefined){
                NEW_SET_ACCOUNT_INFO(this.$qs.stringify(param)).then((response)=>{
                    if(response.data.ReturnCode == 1){
                        this.handleDataChange()
                        // this.$bus.$emit('updateSettingAvatar',this.userInfo.avatar)  // 把导航栏上方的头像更新一下
                        // this.$bus.$emit("changeGender",this.userInfo.ismale)  // 在setting.vue里接收
                    }else{
                        this.$message.error('保存失败');
                    }
                })
            }
        },
        resumeInfo(){   // 重新显示用户信息
            let info = this.$store.state.accountInfo
            this.originInfo = info    // 把接口的信息保存一下
            this.teachingAge = info.AttendedDate
            this.degree = info.Education
            this.jobTitle = info.TeacherPosition
        }
    },
    activated(){
        this.resumeInfo()    
    },
    mounted(){
        this.$bus.$on('getAccountInfo',()=>{    // 修改资料里面回显信息
            this.resumeInfo()
        }) 
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    height: 960px;
    border-radius: 4px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 32px;
    }
    .content{ // 内容区
        margin-left: 32px;
        margin-top: 32px;
        width: 80%;
        .el-form-item{
            margin-bottom: 24px;
        }
        /deep/ .el-form-item__label{  // label和输入框之间的间隔
            padding-right: 16px;
            color: #A9AEB8;
        }
        /deep/ .long-input{  // 长的输入框
            width: 384px;
        }
        .confirm-pwd{ // 确认密码
            margin-bottom: 32px;
        }
        .pwd-img{  // 当前密码和确认密码后面的图标
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 16px;
        }
        .change-container{ // 修改按钮
            display: flex;
            align-items: flex-end;
            .change-text{  // 修改按钮右侧文字
                color: #2DA718;
                font-size: 12px;
                height: 32px;
                margin-left: 16px;
            }
        }
    }
}
</style>