import AliYunOSS from "./oss.js";
import TencentCOS from "./cos.js";
import AWSCOS from "./aws.js";

import { getTotalDefaultConfig } from "./base.js";

/* 封装统一上传类 */

export default class CloudStorage {
    /**
     * @description: 通用上传类
     * @param {object} [options] 配置参数
     * @param {"oss"|"cos"|"aws"} [options.cloudType] 上传类型
     * @param {number} [options.userid] 用户id
     * @param {string} [options.project] 项目名称
     * @param {string} [options.moduleName] 模块名称
     * @param {string} [options.cdn] cdn加速地址
     * @param {string} [options.bucket] bucket
     * @param {string} [options.point] endpoint
     * @param {boolean} [options.isTemp] isTemp
     * @param {string} [options.accessKeyId] accessKeyId
     * @return {CloudStorage}
     */
    constructor(options = {}) {
        let cloud = null;

        const cloudType = options.cloudType || getTotalDefaultConfig().cloudType;
        switch (cloudType) {
            case "oss":
                cloud = new AliYunOSS(options);
                break;
            case "cos":
                cloud = new TencentCOS(options);
                break;
            case "aws":
                cloud = new AWSCOS(options);
                break;
            default:
                break;
        }

        cloud.cloudType = cloudType;

        return cloud;
    }
}
