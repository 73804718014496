<template>
<div>
    <div class="container">
        <div class="title">
            <span>任教设置</span>
        </div>
        <el-divider class="line"></el-divider>
        <div class="content">
            <div class="select-class">
                <el-select v-model="selectedGrade" placeholder="选择年级" @change="changeGrade" size="small" class="select-type-one">
                    <el-option
                    v-for="item in gradeList"
                    :key="item.Idx"
                    :label="item.Name"
                    :value="item.Idx">
                    </el-option>
                </el-select>
                <el-select v-model="selectedClass" placeholder="选择班级" @change="changeClass" size="small" class="select-type-one">
                    <el-option
                    v-for="item in classList"
                    :key="item.Idx"
                    :label="item.Name"
                    :value="item.Idx">
                    </el-option>
                </el-select>
                <div class="teach-other-class" @click="openDialog">
                    <img v-cdnimg class="add-class-icon" src="/Account/images/setting/navigation/class-management/add.png" alt="">
                    任教其他班级
                </div>
            </div>
            <el-dialog
            title="选择班级及任教科目"
            :visible.sync="dialogVisible"
            :width="'500px'"
            class="teach-other-dialog"
            :before-close="handleClose">
                <el-select v-model="dialogGrade" @change="changeDialogGrade" placeholder="选择年级" size="small" class="dialog-select">
                    <el-option
                    v-for="item in dialogGradeList"
                    :key="item.Idx"
                    :label="item.Name"
                    :value="item.Idx">
                    </el-option>
                </el-select>
                <el-select v-model="dialogClass" placeholder="选择班级" size="small" class="dialog-select" @visible-change="showClass($event)">
                    <el-option
                    v-for="item in dialogClassList"
                    :key="item.Idx"
                    :label="item.Name"
                    :value="item.Idx">
                    </el-option>
                </el-select>
                <el-select v-model="dialogSubject" placeholder="选择科目" size="small" class="dialog-select" @visible-change="showSubject($event)">
                    <el-option
                    v-for="item in dialogSubjectList"
                    :key="item.Idx"
                    :label="item.Name"
                    :value="item.Idx">
                    </el-option>
                </el-select>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                    <el-button type="primary" @click="dialogConfirm" size="small">确 定</el-button>
                </span>
            </el-dialog>
            <div class="setting-info">
                <!-- 第一行 -->
                <div class="row-info">  
                    <div class="row-title">班级名称</div>
                    <!-- 正常显示状态 -->
                    <div class="setting-content" v-if="!editClassNameToggle">{{className}}</div>
                    <div class="change-name" v-if="!editClassNameToggle" @click="editClassNameToggle = true" v-show="showChangeName">改名</div>
                    <!-- 编辑状态下 -->
                    <el-input class="setting-content-edit" v-model="className" v-if="editClassNameToggle" size="mini"></el-input>
                    <div class="change-name" v-if="editClassNameToggle" @click="changeClassName">确定</div>
                </div>
                <!-- 第二行 -->
                <div class="row-info">
                    <div class="row-title">创建者</div>
                    <div class="setting-content">{{creator}}</div>
                </div>
                <!-- 第三行 -->
                <div class="row-info">
                    <div class="row-title">班主任</div>
                    <div class="setting-content">{{headTeacher}}</div>
                    <div class="resign" v-show="showResign" @click="resign">辞任</div>
                </div>
                <!-- 第四行 -->
                <div class="row-info last-row-info" >
                    <div class="row-title">任教科目</div>
                    <div class="subject-area">
                        <div class="setting-content" v-for="item in teachingSubjectList" :key="item.Idx">{{item.Name}}</div>
                        <div class="change-subject" @click="editSubjectArea">
                            <div>修改科目</div>
                            <img v-cdnimg :class="{'arrow-down':true, 'arrow-up':isArrowUp}" src="/Account/images/setting/navigation/class-management/changeSubject.png" alt="">
                            <div class="popover-arrow" v-if="isArrowUp"></div>
                        </div>
                    </div>
                </div>
                <!-- 科目选择区域 -->
                <div class="select-subject-area" v-if="isArrowUp">
                    <div class="option-container">
                        <div :class="{'subject-option':true, 'subject-clicked':item.flag == true}" v-for="item in allSubjectList" :key="item.Idx" @click="clickSubjectOption(item)">{{item.Name}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <class-group :stuGroupList="stuGroupList" :selectedClass="selectedClass" :isHeadTeacher="isHeadTeacher"></class-group>
</div>
</template>

<script>
import '@/styles/element-variables.scss';   // element-ui主题色
import ClassGroup from '@/components/setting/ClassManagement/ClassGroup.vue'
import {GET_SCHOOL_PERIOD_GRADE, GET_CLASS_LIST, GET_GRADE_CLASS, GET_SUBJECT, GET_CLASS_INFO, UPDATE_CLASS,
        SET_CLASS_ACCOUNT, SET_CLASS_SUBJECT, GET_STUDENT_LIST, SET_ACCOUNT_INFO} from '@/http/api.js'
export default {
    components:{ClassGroup},
    data() {
        return {
            selectedGrade:'',            // 选择的年级
            selectedClass:'',            // 选择的班级
            gradeList:[],                // 年级列表
            classList:[],                // 班级列表
            className: '',               // 班级名称
            editClassNameToggle:false,   // 是否编辑班级名称状态转换
            creator:'',                  // 创建者
            isHeadTeacher:false,         // 登录账户是否是当前班的班主任
            headTeacher:'',              // 班主任名字
            showChangeName:false,        // 是否显示改名
            showResign:false,            // 是否显示辞任
            teachingSubjectList:[],      // 任教科目
            allSubjectList:[],           // 全部的科目信息
            isArrowUp:false,             // 修改科目右侧上下图标
            dialogVisible:false,         // 任教其他班级 
            dialogGrade:'',              // 弹窗选中的年级       
            dialogClass:'',              // 弹窗选中的班级
            dialogSubject:'',            // 弹窗选中的学科
            dialogGradeList:[],          // 弹窗年级列表
            dialogClassList:[],          // 弹窗班级列表
            dialogSubjectList:[],        // 弹窗科目列表
            stuGroupList:[],             // 下方的学生分组信息
        }
    },
    methods:{
        changeClassName(){  // 更改班级名称
            this.editClassNameToggle = false
            var param = {
                'session': this.getSession(),
                'page': 0,
                'schoolidx': this.loadAccount().schoolidx,
                'gradeidx': this.selectedGrade,
                'classidx': this.selectedClass+'',
                'className': this.className
            };
            // console.log("改名的参数",param)
            UPDATE_CLASS(this.$qs.stringify(param)).then((response)=>{
                // console.log("改名成功")
            })
        },
        resign(){  // 辞任
            var paramSubject = {
                "session": this.getSession(),
                "page": 0,
                "classidx": this.selectedClass,
                "teacherid": 0,
                "subjectidx": 0,
            };
            this.$confirm('确认辞任班主任?', '确认窗口', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '辞任成功!'
                });
                SET_CLASS_SUBJECT(this.$qs.stringify(paramSubject)).then(()=>{
                    // console.log("辞任成功")
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消辞任'
                });          
            });
        },
        editSubjectArea(){  // 修改科目区域的打开和关闭
            this.isArrowUp = !this.isArrowUp
        },
        clickSubjectOption(item){  // 点击修改科目
            // console.log(item)
            var param = {
                "session": this.getSession(),
                "page": 0,
                "classidx": this.selectedClass,
                "teacherid": this.loadAccount().userid,
                "subjectidx": item.Idx
            };
            if(item.flag == false){  // 增加科目
                item.flag = true    // 优化，先把科目加上去，接口如果报错没有加上去的时候再把哪一项删除
                this.teachingSubjectList.push(item)
                SET_CLASS_SUBJECT(this.$qs.stringify(param)).then((response)=>{
                    if(response.data.ReturnCode != 1){  // 没有添加成功
                        item.flag = false  // 把该项高亮去除
                        for(let i=0;i<this.teachingSubjectList.length;i++){  // 从任教科目中删除
                            if(this.teachingSubjectList[i].Idx == item.Idx){
                                index = i
                                this.teachingSubjectList.splice(index,1)
                            }
                        }
                        this.$message.error('新增任教科目失败');
                    }
                })
            }else{  // 辞任科目
                param.teacherid = 0
                this.$confirm('确认辞任?', '确认窗口', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    SET_CLASS_SUBJECT(this.$qs.stringify(param)).then(()=>{
                        item.flag = false
                        var index = null  // 保存需要删除项的下标
                        for(let i=0;i<this.teachingSubjectList.length;i++){
                            if(this.teachingSubjectList[i].Idx == item.Idx){
                                index = i
                                this.teachingSubjectList.splice(index,1)
                            }
                        }
                        this.$message({
                            type: 'success',
                            message: '辞任成功!'
                        });
                    })    
                })
                this.teachingSubjectList.forEach((subject)=>{
                    if(subject.Idx == item.Idx){

                    }
                })
            }

        },
        fetchGradeList(){  // 获取学校对应的年级信息
            let param = {
                onlyme:true,
                page:0,
                schoolidx:this.loadAccount().schoolidx,
                session:this.getSession()
            }
            GET_SCHOOL_PERIOD_GRADE(this.$qs.stringify(param)).then((response)=>{
                var result = response.data.result  // 接口返回的年级信息
                var list = []     // 保存年级信息
                result.forEach((item)=>{
                    list = list.concat(item.ChildList)
                })
                this.gradeList = list
                // 获取年级信息时再获取一下班级信息
                // 年级默认选中第一个
                list[0].Idx ? this.selectedGrade = list[0].Idx : ''
                this.fetchClassList(this.selectedGrade, true)
            })
        },
        fetchClassList(gradeidx, onlyme, callback){  // 年级切换的时候获取班级信息
            var param = {
                'session': this.getSession(), 
                'page': 0,
                'schoolidx': this.loadAccount().schoolidx,
                'gradeidx': gradeidx,
                'onlyme': onlyme
            };
            GET_CLASS_LIST(this.$qs.stringify(param)).then((response)=>{
                var list = response.data.result
                this.classList = list
                // 班级默认选中第一个
                list[0].Idx ? this.selectedClass = list[0].Idx : ''
                this.getClassInfo()   // 获取班级的具体信息
            })
        },
        loadInfo(response){  // 加载班级信息
            // console.log("班级的具体信息",response.data)
            var userId = this.loadAccount().userid  // 当前用户id
            var classInfo = response.data.result[0]  // 班级信息
            var masterId = classInfo.TeacherID       // 班主任的id
            this.className = classInfo.ClassName
            this.creator = classInfo.CreatorName
            this.headTeacher = userId == masterId ? classInfo.TeacherName : "暂无"
            // this.headTeacher = classInfo.AdviserName ? classInfo.AdviserName : "暂无"

            var subjectInfo = response.data.result[1].ChildList  // 全部科目信息
            var teachingList = []       // 任教的科目
            subjectInfo.forEach((item)=>{
                this.$set(item,'flag',false)
                // item.flag = false           // 给每一个科目添加一个flag方便实现高亮效果
                if(item.Teachers != null){
                    item.Teachers.forEach((teacher)=>{
                        if(teacher.TeacherID == userId){  // 任课教师中有当前教师
                            teachingList.push(item)
                            item.flag = true
                        }
                    })
                }
            })
            var masterFlag = (userId == masterId)
            var resignFlag = (userId == masterId) && this.headTeacher != "暂无"
            this.showChangeName = masterFlag ? true : false  // 是否显示改名
            this.isHeadTeacher = masterFlag ? true : false   // 登录用户是否是当前班的班主任
            this.showResign = resignFlag ? true : false     // 是否显示辞任
            this.teachingSubjectList = teachingList
            this.allSubjectList = subjectInfo               // 全部科目
            // console.log("全部科目",this.allSubjectList)
        },
        getClassInfo(){  // 获取班级具体信息
            var param = {
                classidx: this.selectedClass,
                page: 0,
                session: this.getSession()
            }
            // console.log("获取班级具体信息的参数",param)
            GET_CLASS_INFO(this.$qs.stringify(param)).then((response)=>{
                this.loadInfo(response)  // 把信息呈现到页面上
                this.getStuGroup()   // 获取学生的分组信息
                this.$bus.$emit("updateLifeTeam")  // 班级改变的时候需要更新一下生活组的内容
            })
        },
        async getStuGroup(){  // 年级和班级变化时获取学生的分组信息
            var param = {
                'session': this.getSession(),
                'page': 0,
                'classIdx': this.selectedClass,
            }
            await GET_STUDENT_LIST(this.$qs.stringify(param)).then((response)=>{
                // console.log("学生分组信息",response.data.result)
                response.data.result.forEach((item)=>{
                    if(item.ChildList == null){
                        item.ChildList = []  // 如果ChildList是null的话无法实现拖拽效果
                    }
                })
                this.stuGroupList = response.data.result
            })
        },
        changeGrade(){  // 选择年级
            this.editClassNameToggle = false  // 如果处于改名状态让其正常显示
            this.fetchClassList(this.selectedGrade, true)
        },
        changeClass(){  // 选择班级
            this.editClassNameToggle = false  // 如果处于改名状态让其正常显示
            this.getClassInfo()   // 获取班级的具体信息
        },
        openDialog(){  // 打开任教其他班级弹窗
            // 打开弹窗前先重置数据
            this.dialogGrade = ''
            this.dialogClass = ''
            this.dialogSubject = ''
            this.dialogGradeList = []
            this.dialogClassList = []
            this.dialogSubjectList = []
            this.dialogVisible = true
            var param = {
                'session': this.getSession(),
                'page': 0,
                'schoolIdx': this.loadAccount().schoolidx,
                'gradeIdx': 0
            };
            GET_GRADE_CLASS(this.$qs.stringify(param)).then((response)=>{
                this.dialogGradeList = response.data.result[0].ChildList
            })
        },
        handleClose(){  // 关闭任教其他班级弹窗
            this.dialogVisible = false
        },
        showClass(status){ // 弹窗中选择班级下拉框展开关闭触发
            if(status === true){
                if(this.dialogGrade === ''){
                    this.$message({
                        message: '请选择年级',
                        type: 'warning'
                    });
                }
            }
        },
        showSubject(status){ // 弹窗中选择班级下拉框展开关闭触发
            if(status === true){
                if(this.dialogGrade === ''){
                    this.$message({
                        message: '请选择年级',
                        type: 'warning'
                    });
                }
            }
        },
        async dialogConfirm(){  // 任教其他班级点击弹窗确认
            if(this.dialogGrade == ''){
                this.$message({
                    message: '请选择年级',
                    type: 'warning'
                });
            }else if(this.dialogClass == ''){
                this.$message({
                    message: '请选择班级',
                    type: 'warning'
                });
            }else if(this.dialogSubject == ''){
                this.$message({
                    message: '请选择学科',
                    type: 'warning'
                });
            }else{
                var paramAccount = {
                    session: this.getSession(),
                    classIdx: this.dialogClass,
                    uidList: JSON.stringify([this.loadAccount().userid]),
                    add: true,
                    isTeacher: true,
                    page:0
                };
                await SET_CLASS_ACCOUNT(this.$qs.stringify(paramAccount)).then(()=>{
                    // console.log("setClassAccount调用成功")
                })
                var paramSubject = {
                    "session": this.getSession(),
                    "page": 0,
                    "classidx": this.dialogClass,
                    "teacherid": this.loadAccount().userid,
                    "subjectidx": this.dialogSubject,
                };
                await SET_CLASS_SUBJECT(this.$qs.stringify(paramSubject)).then(()=>{
                    // console.log("setClassSubjectTeacher调用成功")
                })
                this.dialogVisible = false
                this.fetchGradeList()   // 提交之后，重新刷新年级和班级信息
            }
        },
        changeDialogGrade(){  // 选中年级时触发
            this.dialogClass = ''
            this.dialogSubject = ''
            // 更新班级信息
            var classParam = {
                'session': this.getSession(),
                'page': 0,
                'schoolIdx': this.loadAccount().schoolidx,
                'gradeIdx': this.dialogGrade
            };
            GET_GRADE_CLASS(this.$qs.stringify(classParam)).then((response)=>{
                this.dialogClassList = response.data.result[1].ChildList
            })

            // 更新学科信息
            var selectedGradeName = ''
            this.dialogGradeList.forEach((item)=>{
                if(item.Idx === this.dialogGrade){
                    selectedGradeName = item.Name
                }
            })
            var subjectParam = {
                'page':0,
                'session':this.getSession(),
                'grade':selectedGradeName
            }
            GET_SUBJECT(this.$qs.stringify(subjectParam)).then((response)=>{
                this.dialogSubjectList = response.data.result
            })
        }
    },
    mounted(){
        if(this.isTeacher()){
            this.fetchGradeList()   // 刚进来的时候获取年级信息
        }
        this.$bus.$on("syncHeadteacherGroup",()=>{
            // console.log("从bus传过来同步成功")
            this.getClassInfo()  // 重新刷新信息
        })
        this.$bus.$on("updateTeachingEffect",()=>{
            // console.log("从bus传过来增加小组成功")
            // this.getClassInfo()  // 重新刷新信息
            this.getStuGroup()
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    min-height: 357px;
    border-radius: 4px;
    margin-bottom: 16px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        font-size: 20px;
        font-weight: 600;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .content{ // 内容区
        padding: 32px;
        .select-class{  // 任教下面的 选择年级班级区域
            display: flex;
            align-items: center;
            ::v-deep .el-input{  // 选择框字体大小
                font-size: 14px;
            }
            .select-type-one{  // 第一行选择框样式
                width: 136px;
                margin-right: 16px;
                font-size: 14px;
            }
            .teach-other-class{  // 任教其他班级
                display: flex;
                width: 128px;
                height: 30px;
                border: 1px solid #FF7513;
                font-size: 14px;
                border-radius: 4px;
                cursor: pointer;
                align-items: center;
                color: #FF7513;
                background: rgba(255,117,19,0.04);
                .add-class-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 12px;
                    margin-right: 4px;
                }
            }
        }
        .teach-other-dialog{  // 任教其他班级弹窗
            ::v-deep .el-dialog__body{
                display: flex;
                justify-content: center;
                ::v-deep .el-input{  // 选择框字体大小
                    font-size: 14px;
                }
                .dialog-select{  // 选择框样式
                    width: 136px;
                    margin-right: 16px;
                    font-size: 14px;
                }
            }
        }
        .setting-info{  // 信息展示的区域
            margin-top: 32px;
            .row-info{  // 每一行信息
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 24px;
                .row-title{  // 每一行左侧的标题
                    width: 56px;
                    height: 22px;
                    margin-right: 16px;
                    color: #A9AEB8;
                    text-align: end;
                }
                .setting-content{  // 班级名称
                    margin-right: 16px;
                    height: 22px;
                    line-height: 22px;
                }
                .setting-content-edit{
                    height: 22px;
                    line-height: 22px;
                    width: 150px;
                    margin-right: 16px;
                    ::v-deep .el-input__inner{  //修改班级名称
                        width: 150px;
                        height: 22px;
                    }
                }
                .change-name{  // 改名
                    font-size: 12px;
                    color: #FF7513;
                    cursor: pointer;
                    height: 22px;
                    line-height: 22px;
                }
                .resign{  // 班主任-辞任
                    color:#FF3B30;
                    font-size: 12px;
                    height: 22px;
                    line-height: 22px;
                    cursor: pointer;
                }
                .subject-area{
                    display: flex;
                    flex:1;
                    flex-wrap: wrap;
                    .change-subject{  // 修改任教科目
                        height: 22px;
                        display: flex;
                        font-size: 12px;
                        color: #FF7513;
                        align-items: center;
                        cursor: pointer;
                        .arrow-down{  // 修改科目右侧三角图标  箭头向下
                            width: 12px;
                            height: 12px;
                            margin-left: 2px;
                            transition: 0.3s;
                        }
                        .arrow-up{  //箭头向上
                            transform: rotate(-180deg);
                            transition: 0.3s;
                        }
                        .popover-arrow{ // 修改科目气泡框的三角形
                            position: relative;
                            content: '';
                            width: 0;
                            height: 0;
                            border-width: 10px;
                            border-style: solid;
                            border-color: transparent transparent #F2F3F5 transparent;
                            left: -50%;
                            top: 15px;
                        }
                    }
                }
            }
            .last-row-info{   // 任教科目的区域
                align-items: flex-start;
                margin-bottom: 10px;
            }
            .select-subject-area{  // 修改科目选择区
                height: 129px;
                background-color: #F2F3F5;
                border-radius: 4px;
                padding: 24px 7px 24px 24px;
                .option-container{
                    height: 100%;
                    background-color: #F2F3F5;
                    display: flex;
                    flex-wrap: wrap;
                    display: -ms-flexbox;
                    -ms-flex-wrap: wrap;
                    align-content: baseline;
                    overflow-y: auto;
                    .subject-option{  // 修改学科下面的每一项
                        // width: 15%;
                        width: 18%;
                        height: 32px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    // 最后一行没有bottom
                    .subject-option:nth-child(5n+1):nth-last-child(-n+5),.subject-option:nth-child(5n+1):nth-last-child(-n+5)~.subject-option{
                    // .subject-option:nth-child(6n+1):nth-last-child(-n+6),.subject-option:nth-child(6n+1):nth-last-child(-n+6)~.subject-option{
                        margin-bottom: 0;
                    }                    
                    .subject-option:not(:nth-child(5n)){  // 每一行最右面一项不显示margin-right
                    // .subject-option:not(:nth-child(6n)){  // 每一行最右面一项不显示margin-right
                        margin-right: calc(2.5%);
                    }
                    .subject-option:hover{  // 悬浮时状态
                        color: #FF7513;
                    }
                    .subject-clicked{  // 选项被点击的时候
                        background-color: #FF7513;
                        color:#FFFFFF !important
                    }
                }
            }
        }
    }
}
</style>