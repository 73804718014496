import Vue from "vue";
import { env } from "../../http/env"
const _vue = Vue.prototype;

/* 工具函数 */

/**
 * @description: 获取默认配置
 * @return {{userid, project, moduleName, cdn, oss: { bucket, point, accessKeyId }, cos: { bucket, point }}}
 */
export function getTotalDefaultConfig() {
    const account = _vue.loadAccount();
    return {
        cloudType: "cos",  // "oss" | "cos" | "aws"
        userid: account ? account.userid : 0,
        project: env == "production" ? "account" : "test_account",  // 项目名
        moduleName: "default",  // 模块名
        oss: {
            isTemp: false,
            bucket: "forcass-res",
            point: "oss-cn-qingdao.aliyuncs.com",
            cdn: "fcdata.forclass.net",
            accessKeyId: "LTAI4GCbXX9VrQHEHY6chrLk",
        },
        cos: {
            bucket: 'forclass-1259419845',
            point: 'ap-beijing',
            cdn: "fordata.forclass.net",
        },
        aws: {
            bucket: "zhyc-oss02",
            point: "obs-gdyj.cucloud.cn",
            cdn: "data.izhyc.cn",
            acl: "public-read"
        }
    }
}

/**
 * @description: 获取文件上传的 key
 * @param {string} filename 上传文件名
 * @param {boolean} [coverfile] 是否覆盖同名文件
 * @return {string} 文件 key
 */
export function getSaveKey(filename, coverfile) {
    if (coverfile) return encodeFileName(filename);

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1 + "").padStart(2, 0);
    const day = (date.getDate() + "").padStart(2, 0);
    const ext = filename.slice(filename.lastIndexOf('.'));

    return `${year}_${month}_${day}_${date.getTime()}${ext}`;
}

/**
 * @description: 获取地址中的文件名
 * @param {string} url 云存储地址
 * @return {string} 文件名
 */
export function getTempFileName(url) {
    return url.slice(url.lastIndexOf("/") + 1);
}

/**
 * @description: 转换附件特殊字符
 * @param {string} fileName 文件名
 * @return {string} 转换后文件名
 */
export function encodeFileName(fileName) {
    const reg = /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\,)(\/)(\<)(\>)(\?)(\)(\')(\")]+/g;
    return encodeURI(fileName.replace(reg, '_'));
}