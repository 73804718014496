import Vue from "vue";
import axios from "axios";
import qs from 'qs';

import { getTotalDefaultConfig, getSaveKey, getTempFileName, encodeFileName } from "./base";
import { accountWsUrl } from "../../http/env";

const _vue = Vue.prototype;

export default class AliYunOSS {
    /**
     * @description: 阿里云上传类
     * @param {object} [options] 阿里云配置参数
     * @param {number} [options.userid] 用户id
     * @param {string} [options.project] 项目名称
     * @param {string} [options.moduleName] 模块名称
     * @param {string} [options.cdn] cdn加速地址
     * @param {string} [options.bucket] bucket
     * @param {string} [options.point] endpoint
     * @param {boolean} [options.isTemp] isTemp
     * @param {string} [options.accessKeyId] accessKeyId
     * @return {AliYunOSS}
     */
    constructor(config) {
        const base = getTotalDefaultConfig();
        const options = Object.assign({}, base, base.oss, config);

        this.userid = options.userid;
        this.cdn = options.cdn;
        this.isTemp = options.isTemp;
        this.bucket = options.bucket;
        this.endpoint = options.point;
        this.accessKeyId = options.accessKeyId;

        this.ossPrefixKey = `${this.isTemp ? "Temp/" : "AttachFiles/"}${this.userid}/`;
        this.prefix = `${options.project}/${options.moduleName}/web/`;
        this.host = `https://${this.bucket}.${this.endpoint}`;

        this.policy = "";
        this.signature = "";

        this.finshed = true;
        this.options = options;
    }

    /**
     * @description: 获取 STS token
     * @return Promise
     */
    getSTSToken() {
        if (this.policy) return Promise.resolve();

        const params = { userid: this.userid, bucket: this.bucket };
        if (this.isTemp) params.isTemp = true;

        const url = `${accountWsUrl}${this.isTemp ? "File/GetOssPolicy2Signature2" : "File/GetOssPolicy2Signature"}`;
        return axios.get(url, { params }).then(result => {
            const data = result.data;

            this.policy = data[0];
            this.signature = data[1];
        });
    }

    /**
     * @description: 阿里云上传 (老接口只支持 postObject)
     * @param {file} file 上传文件
     * @param {object} [options] 配置
     * @param {function} [options.progress] progress
     * @param {function} [options.failed] failed
     * @param {boolean} [options.coverfile] 是否覆盖同名文件
     * @return Promise
     */
    async upload2Cloud(file, options = {}) {
        this.finshed = false;
        _vue.startLoading();

        await this.getSTSToken();

        const fileName = file.name;
        const savekey = this.ossPrefixKey + this.prefix + getSaveKey(fileName, options.coverfile);

        const formData = new FormData();
        formData.append("policy", this.policy);
        formData.append("Signature", this.signature);
        formData.append("bucket", this.bucket);
        formData.append("key", savekey);
        formData.append("OSSAccessKeyId", this.accessKeyId);
        formData.append("success_action_status", "201");

        const encodeName = encodeFileName(fileName);
        formData.append("Content-Disposition", "attachment;filename=" + encodeName + ";filename*=UTF-8''" + encodeName);
        formData.append("file", file);

        return axios.post(this.host, formData, {
            responseType: 'document',
            onUploadProgress: evt => options.progress && options.progress(evt),
        }).then(result => {
            this.finshed = true;
            _vue.stopLoading();

            console.log("上传成功");

            const data = result.data;
            const url = data.getElementsByTagName("Location")[0].childNodes[0].nodeValue;

            return url ? Promise.resolve({ url: this.replaceUrl2CDN(url), tempFileName: getTempFileName(savekey), fileName }) : Promise.reject(result);
        }).catch(error => {
            this.finshed = true;
            _vue.stopLoading();

            console.log("上传失败");

            options.failed && options.failed(error);
        })
    }

    /**
     * @description: 阿里云地址转 cdn 加速地址
     * @param {string} url 阿里云地址
     * @return {string} cdn 地址
     */
    replaceUrl2CDN(url) {
        return url ? url.replace(this.bucket + "." + this.endpoint, this.cdn) : url;
    }

    /**
     * @description: 删除阿里云文件
     * @param {string} fileName diskfilename 文件路径
     * @param {boolean} [isTemp] 是否是临时文件
     * @return Promise
     */
    deleteCloudFile(fileName, isTemp) {
        if (!this.userid || !fileName || fileName.trim() == "") return Promise.reject();

        _vue.startLoading();
        const savekey = this.prefix + getTempFileName(fileName);
        const url = accountWsUrl + (isTemp ? "File/DeleteOssObject2" : "File/DeleteOssObject");
        const data = { userid: this.userid, key: savekey, bucket: this.bucket };

        return axios.post(url, qs.stringify(data)).then(result => {
            _vue.stopLoading();
            return result.data == "1" ? Promise.resolve() : Promise.reject()
        })
    }

    /**
     * @description: 获取阿里云图片特殊转化地址
     * @param {string} url 阿里云地址
     * @param {object|string} options 配置参数
     * @return {string} 处理后的地址
     */
    handleImgUrl(url, options) {
        if (options) {
            url += "?x-oss-process=image";

            if (typeof (options) == "object") {
                for (let key in options) {
                    url += "/" + key + "," + options[key];
                }
            } else {
                url += options;
            }
        }

        return url;
    }
}