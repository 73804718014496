<template>
  <el-dialog
    title="编辑头像"
    :visible.sync="dialogVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="540px"
  >
    <div class="avatar">
      <div class="avatar-tip" v-show="!options.img">请上传小于5M的JPG、JPEG或PNG文件作为您的头像</div>
      <div class="avatar-tip" v-show="options.img">鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置</div>
      <div class="avatar-content">
        <div class="avatar-left">
          <div v-show="!options.img">
            <UploadFile
              ref="UploadFile"
              accept="image/png, image/jpeg, image/jpg"
              :fileSize="5 * 1024 * 1024"
              :formats="['png', 'jpg', 'jpeg']"
              :showFileList="false"
              :isAsync="true"
              :validFile="beforeUpload"
              @onSuccess="onSuccess"
            >
              <el-button size="small" type="primary" ref="uploadBtn">选择图片</el-button>
            </UploadFile>
          </div>
          <div v-show="options.img" class="avatar-left-crop">
            <vueCropper
              class="crop-box"
              ref="cropper"
              :img="options.img"
              :autoCrop="options.autoCrop"
              :fixedBox="options.fixedBox"
              :canMoveBox="options.canMoveBox"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :centerBox="options.centerBox"
              @realTime="realTime">
            </vueCropper>
          </div>
        </div>
        <div class="avatar-right">
          <div class="avatar-right-div" v-for="(item,index) in previewsDiv" :style="item.style" :key="index">
            <div v-show="options.img" :class="previews.div" class="avatar-right-previews" :style="item.zoomStyle">
              <img v-cdnimg :src="previews.url" :style="previews.img">
            </div>
          </div>
          <div>* 真实头像 140×140px</div>
          <div class="avatar-right-text">
            <el-button v-if="options.img" type="primary" plain size="medium" @click="uploadPreviews">重新上传</el-button>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog" size="small">取 消</el-button>
      <el-button type="primary" @click="submitAvatar" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// 腾讯云OSS存储
import {SET_ACCOUNT_AVATAR} from '@/http/api.js'
import UploadFile from '@/components/UploadFile.vue';
  export default {
    name: "EditAvatar",
    props: {
      dialogVisible: {
        type: Boolean,
        default: true
      }
    },
    components: {UploadFile},
    data() {
      return {
        imgFile:{},   // 图片元数据
        //vueCropper组件 裁剪配置信息
        options: {
          img: '',  //原图文件
          autoCrop: true,  //默认生成截图框
          fixedBox: true,  //固定截图框大小
          canMoveBox: true,    //截图框不能拖动
          autoCropWidth: 140,  //截图框宽度
          autoCropHeight: 140, //截图框高度
          centerBox: true,    //截图框被限制在图片里面
        },
        //实时预览图数据
        previews: {},
        //实时预览图样式
        previewsDiv: [
          //108px 预览样式
          {
            style: {
              width: '140px',
              height: '140px',
              margin: '0 auto'
            },
            // zoomStyle: {
            //   zoom: 0.54
            // }
          }
        ]
      }
    },
    methods: {
      beforeUpload(file) {
        this.imgFile = file  // 图片元数据保存下来
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.options.img = e.target.result //base64
        }
      },
      // 上传成功
      onSuccess(file, fileList, res) {
        this.updateAvatar(res.url);
        this.options.img = ''; // 清空数据
      },
      //实时预览数据
      realTime(data) {
        this.previews = data
      },
      //重新上传
      uploadPreviews() {
        this.$refs.uploadBtn.$el.click()
      },
      //获取截图信息
      // 修改好头像之后点击确定
      submitAvatar() {
        // 获取截图的 blob 数据
        this.$refs.cropper.getCropBlob((data) => {
          data.name = this.imgFile.name
          this.$refs['UploadFile'].httpRequest({file: data})
        })
      },
      // 图片存到OSS中且返回地址，地址转换为CDN地址之后需要调接口保存一下图片的地址
      updateAvatar(url) {
        let param = {
          avatar:url,
          page:0,
          session:this.getSession()
        }
        SET_ACCOUNT_AVATAR(this.$qs.stringify(param)) // 等接口返回url成功之后再更新本地的头像
        this.$bus.$emit('updateLocalAvatar',url)  // 把本地的用户头像更新一下

        //调用父组件关闭弹框方法
        this.$emit('closeDialog')
      },
      //关闭弹框
      closeDialog() {
        //调用父组件关闭弹框方法
        this.$emit('closeDialog')
        //重置 data 数据。(Object.assign是对象深复制  this.$data是组件内的数据对象 this.$options.data()是原始的数据)
        Object.assign(this.$data, this.$options.data())
      },
    },
    created(){},
    mounted(){}
  }
</script>

<style lang="scss" scoped>
  /deep/ .el-dialog__header {
    padding: 24px 0 11px 28px;
  }
  /deep/ .el-dialog__title {
    color: #333333;
  }
  /deep/ .el-dialog__body {
    padding: 0 28px;
  }
  /deep/ .el-dialog__footer {
    padding: 20px 28px;
    .el-button {
      width: 70px;
    }
  }
  .avatar {
    display: flex;
    flex-direction: column;
    .avatar-tip{
      margin-bottom: 10px;
      color:#A9AEB8
    }
    .avatar-content{
      display: flex;
      .avatar-left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 280px;
        background-color: #F0F2F5;
        margin-right: 10px;
        border-radius: 4px;
        .avatar-left-crop {
          width: 320px;
          height: 280px;
          position: relative;
          .crop-box {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow: hidden
          }
        }
      }
      .avatar-right {
        width: 150px;
        border-radius: 4px;
        padding: 16px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #A9AEB8;
        .avatar-right-div {
          border: 3px solid #ffffff;
          border-radius: 50%;
          background-color: #F0F2F5;
        }
        .avatar-right-previews {
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 50%;
        }
        .avatar-right-text {
          text-align: center;
          margin-top: 50px;
          font-size: 14px; 
          span {
            color: #666666;
          }
        }
      }
    }
  }

</style>