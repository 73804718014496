import Vue from "vue";
import axios from "axios";
import COS from "cos-js-sdk-v5";
import { getTotalDefaultConfig, getSaveKey, getTempFileName, encodeFileName } from "./base";
import { webapiUrl } from "../../http/env";

const _vue = Vue.prototype;

export default class TencentCOS {
    /**
     * @description: 腾讯云上传类
     * @param {object} [options] 腾讯云配置参数
     * @param {number} [options.userid] 用户id
     * @param {string} [options.project] 项目名称
     * @param {string} [options.moduleName] 模块名称
     * @param {string} [options.cdn] cdn加速地址
     * @param {string} [options.bucket] bucket
     * @param {string} [options.point] endpoint
     * @return {TencentCOS}
     */
    constructor(config) {
        const base = getTotalDefaultConfig();
        const options = Object.assign({}, base, base.cos, config);

        this.cdn = options.cdn;
        this.bucket = options.bucket;
        this.region = options.point;

        this.prefix = `${options.userid}/${options.project}/${options.moduleName}/web/`;

        this.options = options;
        this.finshed = true;

        this.cos = new COS({
            getAuthorization: async (options, callback) => {
                const result = await this.federationTokenByBucket();
                const info = result.data.data;
                if (info) {
                    const credentials = info.Credentials;
                    callback({
                        XCosSecurityToken: credentials.Token,
                        Authorization: COS.getAuthorization({
                            SecretId: credentials.TmpSecretId,
                            SecretKey: credentials.TmpSecretKey,
                            Method: options.Method,
                            Pathname: options.Pathname,
                        })
                    });
                } else {
                    console.log("获取签名出错");
                }
            },
            UploadCheckContentMd5: true // 是否检测 md5
        });
    }

    /**
     * @description: 腾讯云地址转 cdn 加速地址
     * @param {string} url 腾讯云地址
     * @return {string} cdn 地址
     */
    replaceUrl2CDN(url) {
        return url ? url.replace(this.bucket + ".cos." + this.region + ".myqcloud.com", "https://" + this.cdn) : url;
    }

    /**
     * @description: 腾讯云上传
     * @param {file} file 上传文件
     * @param {object} [options] 配置
     * @param {function} [options.progress] progress
     * @param {function} [options.failed] failed
     * @param {boolean} [options.coverfile] 是否覆盖同名文件
     * @return
     */
    upload2Cloud(file, options = {}) {
        this.finshed = false;
        // _vue.startLoading();

        const fileName = file.name;
        const savekey = this.prefix + getSaveKey(fileName, options.coverfile);
        const encodeName = encodeFileName(fileName);

        return new Promise((resolve, reject) => {
            this.cos.putObject({
                Bucket: this.bucket,
                Region: this.region,
                Key: savekey,
                Body: file,
                SliceSize: 1024 * 1024 * 5, // 大于5mb才进行分块上传
                Headers: {
                    'content-disposition': "attachment;filename=" + encodeName + ";filename*=UTF-8''" + encodeName
                },
                onProgress: evt => options.progress && options.progress(evt),
            }, (err, data) => {
                this.finshed = true;
                // _vue.stopLoading();

                if (err) {
                    console.log("上传失败");
                    options.failed && options.failed(err);
                    reject(err);
                }
                if (data) {
                    console.log("上传成功");
                    resolve({ url: this.replaceUrl2CDN(data.Location), tempFileName: getTempFileName(savekey), fileName });
                }
            });
        });
    }

    /**
     * @description: 删除腾讯云文件
     * @param {string} fileName diskfilename 文件路径
     * @return
     */
    deleteCloudFile(fileName) {
        if (!fileName || fileName.trim() == "") return Promise.reject();

        // _vue.startLoading();
        const savekey = this.prefix + getTempFileName(fileName);
        return new Promise((resolve, reject) => {
            // _vue.stopLoading();
            this.cos.deleteObject({
                Bucket: this.bucket,
                Region: this.region,
                Key: savekey
            }, function (err, data) {
                if (err) reject();
                if (data) resolve();
            });
        })
    }

    federationTokenByBucket() {
        const url = webapiUrl + "PublicToolSCenter/api/tencent_cloud/FederationTokenByBucket";
        return axios.get(url, {
            params: { bucket: this.bucket },
            headers: {
                "session": _vue.getSession(),
                "Content-Type": 'application/x-www-form-urlencoded'
            },
        })
    }
}
