<template>
<div class="container">
    <div class="title">
        <div>
            <span>个人资料</span>
        </div>
        <div>
            <el-button v-if="isClient()" type="primary" size="mini" @click="goBack">返回</el-button>
        </div>
    </div>
    <el-divider class="line"></el-divider>
    <div class="content">
        <el-form class="el-form-container" :label-position="labelPosition" label-width="100px" :model="userInfo" :rules="rules">
            <div class="avatar-container">
                <el-form-item class="avatar-text" label="更换头像"></el-form-item>
                <div class="img-container" @click="editAvatar">
                    <img v-cdnimg class="avatar-img" :src="userInfo.avatar" v-show="userInfo.avatar != null && userInfo.avatar != ''">
                    <img v-cdnimg class="avatar-img" src="/Account/images/default-user.png" v-show="userInfo.avatar == null || userInfo.avatar == ''">
                    <img v-cdnimg class="avatar-img-cover" src="/Account/images/setting/avatar/changeAvatar.png" alt="">
                </div>
            </div>
            <el-form-item label="用户名">
                <!-- <div class="short-input">{{userInfo.showname}}</div> -->
                <div class="short-input">{{userInfo.account}}</div>
                <!-- <el-input :disabled="ifEditUserName" v-model="userInfo.showname" class="short-input" clearable></el-input> -->
            </el-form-item>
            <el-form-item label="真实姓名">
                <el-input maxlength="20" show-word-limit :disabled="ifEditUserName" v-model="userInfo.realname" class="short-input" clearable spellcheck="false"></el-input>
                <!-- <el-input :disabled="disableEditRealName" v-model="userInfo.realname" class="short-input" clearable spellcheck="false"></el-input> -->
            </el-form-item>
            <el-form-item label="班级" v-show="isStudent()">
                <el-input disabled v-model="userInfo.classname" class="short-input" clearable spellcheck="false"></el-input>
            </el-form-item>
            <el-form-item label="学校" v-if="currentPlatform != '联想'">
                <div class="long-input">{{userInfo.school}}</div>
            </el-form-item>
            <!-- <el-form-item label="学校" v-if="currentPlatform == '联想'"> -->
            <el-form-item label="学校" v-if="currentPlatform == '联想' && isShowSchool==true">
                <div class="long-input" v-if="lenovoSchool !=''">{{lenovoSchool}}</div>
                <div class="apply-school" 
                @click="applySchool" 
                v-if="lenovoSchool =='' && joinStatus != '0'">
                申请加入
                </div>
                <div class="apply-school" @click="openApplyDialog" v-if="joinStatus == '0'">待审核</div>
            </el-form-item>
            <!-- <el-form-item label="区域" v-show="currentPlatform != '联想'"> -->
            <el-form-item label="区域" v-if="isShowRegion==true">
                <div class="long-input">{{userInfo.region}}</div>
                <!-- <el-input disabled v-model="userInfo.region" class="long-input" clearable></el-input> -->
            </el-form-item>
            <el-form-item label="身份证号">
                <el-input v-model="userInfo.idNumber" class="long-input" clearable spellcheck="false"></el-input>
            </el-form-item>
            <el-form-item label="学号" v-show="isStudent()">
                <el-input v-model="userInfo.studentCode" class="long-input" clearable spellcheck="false"></el-input>
            </el-form-item>
            <el-form-item label="入学年份" v-show="isStudent()">
                <el-date-picker
                    v-model="userInfo.attendedDate"
                    type="year"
                    valueFormat="yyyy-MM-dd"
                    placeholder="选择年">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="性别">
                <el-radio v-model="userInfo.ismale" :label="true">男</el-radio>
                <el-radio v-model="userInfo.ismale" :label="false">女</el-radio> 
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="userInfo.email" class="long-input" clearable spellcheck="false"></el-input>
            </el-form-item>
            <div class="item-wrap" v-show="modifyEmail">
                <el-form-item label="图形验证码" prop="emailVerify">
                    <el-input v-model="userInfo.emailVerify" class="long-input" clearable></el-input>
                </el-form-item>
                <verify-code ref="emailVerify" :verifyCode.sync="userInfo.emailVerify" stampName="emailVerify"></verify-code>
            </div>
            <div class="item-wrap" v-show="modifyEmail">
                <el-form-item label="邮箱验证码" prop="emailCode">
                    <el-input v-model="userInfo.emailCode" class="long-input" clearable></el-input>
                </el-form-item>
                <debounce-code tips="获取邮箱验证码" :loading.sync="emailLoading" @click.native="emailSend"></debounce-code>
            </div>
            <div class="phone-container">
                <el-form-item label="手机号码">
                    <el-input v-model="userInfo.phonenumber" class="long-input" clearable></el-input>
                </el-form-item>
                <div class="item-wrap" v-show="modifyPhone">
                    <el-form-item label="图形验证码" prop="phoneVerify">
                        <el-input v-model="userInfo.phoneVerify" class="long-input" clearable></el-input>
                    </el-form-item>
                    <verify-code ref="phoneVerify" :verifyCode.sync="userInfo.phoneVerify" stampName="phoneVerify"></verify-code>
                </div>
                <div class="item-wrap" v-show="modifyPhone">
                    <el-form-item label="短信验证码" prop="phoneCode">
                        <el-input v-model="userInfo.phoneCode" class="long-input" clearable></el-input>
                    </el-form-item>
                    <debounce-code tips="获取短信验证码" :loading.sync="phoneLoading" @click.native="phoneSend"></debounce-code>
                </div>
            </div>
            <el-form-item>
                <div class="save-container">
                    <el-button type="primary" size="small" @click="saveUserInfo">保 存</el-button>
                    <span class="save-text" v-show="successTip">个人资料已保存</span>
                </div>
            </el-form-item>
        </el-form>
    </div>
    <edit-avatar :dialogVisible="dialogVisible" @closeDialog="closeDialog"></edit-avatar>
    <!-- 申请加入学校弹窗 -->
    <apply-school
    v-if="applySchoolVisible" 
    @closeApplySchoolDialog="closeApplySchoolDialog"
    :currentStatus='currentStatus'
    :applyInfo='applyInfo'
    :applySchoolVisible="applySchoolVisible">
    </apply-school>
    
    <!-- 跳转到班级管理和设备管理时需要重新输入密码进行验证 -->
    <confirm-login 
    @closeLoginDialog="closeLoginDialog"
    @cancelLoginDialog="cancelLoginDialog"
    :loginConfirmDialog="loginConfirmDialog">
    </confirm-login>
</div>
</template>

<script>
import {SET_ACCOUNT_INFO, GET_APPLY_STATUS, NEW_SET_ACCOUNT_INFO, VERIFICATION_SMS, VERIFICATION_EMAIL,SETACCOUNT_PHONE,SETACCOUNT_EMAIL} from '@/http/api.js'
import ConfirmLogin from '@/components/setting/Widgets/ConfirmLogin.vue';
import EditAvatar from '../../../components/setting/ModifyInfo/EditAvatar.vue';
import ApplySchool from '../../../components/setting/ModifyInfo/ApplySchool.vue';
import VerifyCode from './VerifyCode.vue';
import DebounceCode from './DebounceCode.vue';
import '@/styles/element-variables.scss';   // element-ui主题色
export default {
    components:{ConfirmLogin,EditAvatar, ApplySchool,VerifyCode,DebounceCode},
    data() {
        return {
            labelPosition: 'right', // 表单左侧名称右对齐
            userInfo: {             // 用户信息
                avatar:'',          // 头像地址
                showname: '',       // 用户名
                realname: '',       // 真实姓名
                classname:'',       // 班级
                school: '',         // 学校
                region: '',         // 区域
                idNumber:'',        // 身份证号
                studentCode:'',     // 学号
                attendedDate:'',    // 入学年份
                ismale:true,        // 性别  true是男生 false是女生
                email:'',           // 邮箱
                phonenumber:'',     // 电话号码
                account:'',         // 用户名（登录账号）
                emailVerify: "",
                emailCode: "",
                phoneVerify: "",
                phoneCode: "",
            },
            emailLoading: false,
            phoneLoading: false,
            prevEmail: "",
            prevPhone: "",
            ifEditUserName:true,        // 是否可以编辑真实姓名
            rules:{
                email:[
                    {required:true, message:'请填写邮箱', trigger:'blur'}
                ],
                emailVerify:[
                    {required:true, message:'请填写验证码', trigger:'blur'}
                ],
                emailCode:[
                    {required:true, message:'请填写邮箱验证码', trigger:'blur'}
                ],
                phoneVerify:[
                    {required:true, message:'请填写验证码', trigger:'blur'}
                ],
                phoneCode:[
                    {required:true, message:'请填写短信验证码', trigger:'blur'}
                ],
            },
            dialogVisible:false,   // 编辑头像的弹窗是否打开
            successTip:false,      // 用户信息保存之后显示已保存提示
            loginConfirmDialog:false,  // 验证登录的弹窗
            applySchoolVisible:false,  // 是否显示申请学校
            joinStatus:'1',     // 加入状态 3代表需要申请加入
            currentStatus:'申请加入',    // 申请加入和待审核两种状态
            applyInfo:{},          // 申请学校的信息
            currentPlatform:'',    // 当前平台
            lenovoSchool:'',
            isShowSchool:true,      // 是否显示学校
            isShowRegion:true,      // 是否显示区域
        }
    },
    computed:{
        // gender(){  // 帮助实现监听userInfo里的ismale属性
        //     return this.userInfo.ismale
        // },
        modifyEmail() {
            return this.prevEmail != this.userInfo.email;
        },
        modifyPhone() {
            return this.userInfo.phonenumber && this.prevPhone != this.userInfo.phonenumber;
        },
        disableEditRealName(){
            var role = this.loadAccount().role
            if(role == "学校管理员" || role == "区域管理员"){
                return false
            }else{
                return true
            }
        },
        isZoneRole(){
            return this.$store.getters.isZoneRole
        },
        isSchoolRole(){
            return this.$store.getters.isSchoolRole
        }
    },
    watch:{},
    methods:{
        emailSend() {
            if (this.emailLoading) return;

            const code = this.userInfo.emailVerify;
            const email = this.userInfo.email.trim();
            const codeId = this.$refs.emailVerify.stamp;
            this.userInfo.emailCode = "";

            const emailRegex = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if (!emailRegex.test(email)) {
                this.$message.error('请输入有效邮箱');
                return;
            }
            if (!code) {
                this.$message.error('请输入图形验证码');
                return;
            }

            const body = {
                signName: "Lenovo",
                template: "Default",
                emailNumber: email,
                cloudServiceProvider: 1,
            }
            VERIFICATION_EMAIL(body, codeId, code).then(result => {
                const data = result.data;
                if (data.status.code == 200) {
                    this.emailLoading = true;
                } else {
                    this.$refs.emailVerify.updateCode();
                    this.$message.error(data.status.message);
                }
            });
        },
        phoneSend() {
            if (this.phoneLoading) return;

            const code = this.userInfo.phoneVerify;
            const phoneNumber = this.userInfo.phonenumber;
            const codeId = this.$refs.phoneVerify.stamp;
            this.userInfo.phoneCode = "";

            if (!phoneNumber) {
                this.$message.error('请输入手机号');
                return;
            }
            const phoneRegex = /^1[3-9]\d{9}$/;
            if (!phoneRegex.test(phoneNumber)) {
                this.$message.error('手机号无效');
                return;
            }
            if (!code) {
                this.$message.error('请输入图形验证码');
                return;
            }

            const body = {
                signName: "Lenovo",
                template: "Common",
                phoneNumber: phoneNumber,
                cloudServiceProvider: 2,
            };
            VERIFICATION_SMS(body, codeId, code).then(result => {
                const data = result.data;
                if (data.status.code == 200) {
                    this.phoneLoading = true;
                } else {
                    this.$refs.phoneVerify.updateCode();
                    const message = data.status.message;
                    const match = message.match(/Invalid Operation:"(.*)"/);
                    const result = match ? match[1] : message;
                    this.$message.error(result);
                }
            });
        },
        isClient(){
            return this.$route.query.path ? true :false
        },
        goBack(){   // 页面后退
            let query = this.$route.query;
            location.href = `${location.origin}${query.path}`;
        },
        updateUserInfo(data){   // 页面初始化的时候更新一下用户信息
            this.userInfo = {
                avatar: data.avatar || '',
                showname: data.showname || '',
                classname: data.classname || '',
                realname: data.realname || '',
                school: data.schoolname ||'',
                region: data.zonename ||'',
                idNumber: data.identity || '',
                ismale: data.ismale,
                email: data.EMail ||'',
                attendedDate: data.Birthdate ||'',
                phonenumber: data.PhoneNumber ||'',
                account: data.account || '',
                emailVerify: "",
                emailCode: "",
                phoneVerify: "",
                phoneCode: "",
            } 
            this.emailLoading = false;
            this.phoneLoading = false;
            this.prevEmail = data.EMail || '';
            this.prevPhone = data.PhoneNumber || '';
            if(location.href.indexOf('lenovo') != -1){
                if(this.isSchoolRole){   // 学校角色
                    this.isShowSchool = true
                    this.isShowRegion = false
                }else if(this.isZoneRole){
                    this.isShowSchool = false
                    this.isShowRegion = true
                }
            }
        },
        editAvatar(){  // 打开编辑头像弹窗
            this.dialogVisible = true
        },
        closeDialog(){   // 关闭编辑头像弹窗
            this.dialogVisible = false
        },
        isValidIdentity(sId) {  // 验证身份证号码
            // 参考：https://blog.csdn.net/weixin_40967353/article/details/109671821         
            let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
            let _IDre15 =  /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
            // 校验身份证：
            if( _IDRe18.test( sId ) || _IDre15.test( sId )  ) {
                return true
            } else {
                return false
            }
        },
        validateAccountForm(){  // 保存用户信息之前需要验证表单
            // 参数不能为null
            if(this.userInfo.idNumber == null){
                this.userInfo.idNumber = ""
            }
            if(this.userInfo.phonenumber == null){
                this.userInfo.phonenumber = ""
            }
            if(this.userInfo.realname == null){
                this.userInfo.realname = ""
            }
            if(this.userInfo.avatar != null){
                var avatar = this.userInfo.avatar.indexOf("../images") >= 0 ? null : this.userInfo.avatar.trim();
            }
            if (this.userInfo.showname.trim() == "") {
                this.$message.error('请填写用户名');
                return;
            }
            // if (this.userInfo.realname.trim() == "") {
            //     this.$message.error('请填写真实姓名');
            //     return;
            // }
            var emailRegex = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
            if (!emailRegex.test(this.userInfo.email.trim())) {
                this.$message.error('请输入有效邮箱');
                return;
            }
            if (this.modifyEmail && (!this.userInfo.emailVerify || !this.userInfo.emailCode)) {
                this.$message.error('请输入邮箱相关验证码');
                return;
            }
            if (this.userInfo.idNumber != "" && !this.isValidIdentity(this.userInfo.idNumber)) {
                this.$message.error('身份证号无效');
                return;
            }
            // var stuNumRegex = /^[a-zA-Z0-9][0-9]{18}$/;
            // var stuNumRegex = /^[a-zA-Z0-9][0-9]*$/;
            // if (this.isStudent() && this.userInfo.studentCode != "" && !stuNumRegex.test(this.userInfo.studentCode)) {
            //     this.$message.error('学号无效');
            //     return;
            // }
            // var currYear = new Date().getFullYear();
            // if (this.isStudent() && this.userInfo.attendedDate != "" && !(this.userInfo.attendedDate > 1900 && this.userInfo.attendedDate <= currYear)) {
            //     this.$message.error('入学年份无效');
            //     return;
            // }
            var phoneRegex = /^1[3-9]\d{9}$/;
            if (this.userInfo.phonenumber != "" && !phoneRegex.test(this.userInfo.phonenumber)) {
                this.$message.error('手机号无效');
                return;
            }
            if (this.modifyPhone && (!this.userInfo.phoneVerify || !this.userInfo.phoneCode)) {
                this.$message.error('请输入手机相关验证码');
                return;
            }
            let finalParam = {
                session:this.getSession(),
                updateInfos:JSON.stringify([
                    { Type: 5, Name: this.userInfo.avatar || '' },
                    { Type: 7, Name: this.userInfo.realname || '' },
                    { Type: 10, Name: this.userInfo.studentCode || '' },
                    { Type: 9, Name: this.userInfo.attendedDate!='' && this.userInfo.attendedDate.split('')[0].replaceAll('/','-') || '' },
                    { Type: 6, Name: this.userInfo.ismale ? '男' : '女' },
                    // { Type: 11, Name: this.userInfo.email.trim() || '' },
                    { Type: 12, Name: this.userInfo.phonenumber.trim() || '' },
                    { Type: 8, Name: this.userInfo.showname.trim() || '' },
                    { Type: 17, Name: this.userInfo.idNumber.trim() || '' }
                ])
            }
            return finalParam;
        },
        fetchModifyEmail() {
            return new Promise(resolve => {
                if (this.modifyEmail) {
                    const param = {
                        session: this.getSession(),
                        email: this.userInfo.email,
                        code: this.userInfo.emailCode,
                    };
                    SETACCOUNT_EMAIL(this.$qs.stringify(param)).then(result => {
                        const data = result.data;
                        if (data.ReturnCode != 1) {
                            this.$message.error(data.ReturnText);
                            resolve(false);
                        } else {
                            this.prevEmail = this.userInfo.email;
                            this.$refs.emailVerify.updateCode();
                            this.userInfo.emailCode = "";
                            resolve(true);
                        }
                    });
                } else {
                    resolve(true);
                }
            });
        },
        fetchModifyPhone() {
            return new Promise(resolve => {
                if (this.modifyPhone) {
                    const param = {
                        session: this.getSession(),
                        code: this.userInfo.phoneCode,
                        phone: this.userInfo.phonenumber,
                    };
                    SETACCOUNT_PHONE(this.$qs.stringify(param)).then(result => {
                        const data = result.data;
                        if (data.ReturnCode != 1) {
                            this.$message.error(data.ReturnText);
                            resolve(false);
                        } else {
                            this.prevPhone = this.userInfo.phonenumber;
                            this.$refs.phoneVerify.updateCode();
                            this.userInfo.phoneCode = "";
                            resolve(true);
                        }
                    });
                } else {
                    this.prevPhone = this.userInfo.phonenumber;
                    resolve(true);
                }
            });
        },
        async saveUserInfo(){  // 保存用户信息
            let param = this.validateAccountForm()   // 调接口保存的参数
            if(param != undefined){
                const resolveEmail = await this.fetchModifyEmail();
                if (!resolveEmail) return;
                const resolvePhone = await this.fetchModifyPhone();
                if (!resolvePhone) return;
                NEW_SET_ACCOUNT_INFO(this.$qs.stringify(param)).then((response)=>{
                    if(response.data.ReturnCode == 1){
                        this.$message.success('保存成功');
                        // this.successTip = true
                        // setTimeout(() => {
                        //     this.successTip = false
                        // }, 2000);
                        this.$bus.$emit('updateSettingAvatar',this.userInfo.avatar)  // 把导航栏上方的头像更新一下
                        this.$bus.$emit("changeGender",this.userInfo.ismale)  // 在setting.vue里接收
                    }else{
                        this.$message.error('保存失败');
                    }
                })
            }
        },
        closeLoginDialog(){  // 关闭验证密码的弹窗（跳到班级管理和设备管理的时候会弹出）
            var confirmStatus = "device_authorized_" + this.loadAccount().userid;
            this.updateSession(confirmStatus,0)   // 更新一下状态，下次还是需要输密验证
            this.loginConfirmDialog = false
            this.$bus.$emit("updateCurrentTag",0)  // 失败的时候还是点击修改资料标签
        },
        cancelLoginDialog(){  // 取消弹窗
            var confirmStatus = "device_authorized_" + this.loadAccount().userid;
            this.updateSession(confirmStatus,0)   // 更新一下状态，下次还是需要输密验证
            this.loginConfirmDialog = false
            this.$bus.$emit("updateCurrentTag",0)  // 失败的时候还是点击修改资料标签
        },
        applySchool(){  // 申请学校
            this.currentStatus = '申请加入'
            this.applySchoolVisible = true   // 显示申请学校弹窗
        },
        openApplyDialog(){
            this.currentStatus = '待审核'
            this.applySchoolVisible = true   // 显示待审核弹窗
        },
        closeApplySchoolDialog(){  // 关闭申请学校的弹窗
            this.applySchoolVisible = false   // 显示申请学校弹窗
        },
        getApplySchoolStatus(){  // 获取加入学校的申请状态
            let param = {
                session:this.getSession()
            }
            GET_APPLY_STATUS(this.$qs.stringify(param)).then((res)=>{
                if(this.isValid(res.data.result)){
                    let result = res.data.result[0]
                    if(result.JoinStatus == 1){    // 1代表已经加入，所以显示学校就行
                        this.joinStatus = '1'
                        this.lenovoSchool = result.SchoolName
                    }else if(result.JoinStatus == 0){  // 0表示待审核
                        this.joinStatus = '0'
                        this.lenovoSchool = ''
                        this.currentStatus = '待审核'
                        this.applyInfo = result
                    }
                }else{
                    this.lenovoSchool = ''
                    this.joinStatus = ''
                }
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        var confirmStatus = "device_authorized_" + this.loadAccount().userid;
        if(this.getSessionVal(confirmStatus) == 1){  // 有值说明之前有验证登录过
            next(true)
        }else{
            if(to.name == "班级管理" || to.name == "设备管理"){

               // console.log("修改资料-触发了二次登录验证",to);
                
                // ----------------------------------原来的二次登录逻辑------------------------------
                this.loginConfirmDialog = true
                this.$bus.$on("confirmLoginSucc",(status)=>{
                    if(status == 'success'){  // 验证成功
                        this.updateSession(confirmStatus,1)   // 更新一下状态
                        this.loginConfirmDialog = false
                        next(true)  // 验证成功之后可以放行
                    }else{   // 验证失败
                        this.$bus.$emit("updateCurrentTag",0)  // 失败的时候还是点击修改资料标签
                        next(false)
                    }
                })
            }else{  // 不去这俩标签不用拦截
                next(true)
            }
        }
    },
    mounted(){
        this.$bus.$on('updateLocalAvatar',(url)=>{  // 更新完头像确定之后，更新一个本地头像   
            this.userInfo.avatar = url
        })
        this.$bus.$on('getAccountInfo',(data)=>{
            this.updateUserInfo(data)
        })
        this.getApplySchoolStatus()   // 获取加入学校状态的返回值
        if(location.href.indexOf('lenovo') != -1){
            this.currentPlatform = '联想'
            this.ifEditUserName = false    // 联想平台的时候可以编辑用户名
        }else{
            this.currentPlatform = ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    height: 1054px;
    border-radius: 4px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 32px;
    }
    .content{ // 内容区
        margin-left: 32px;
        margin-top: 32px;
        width: 80%;
        .el-form-container{
            ::v-deep .el-form-item__label{
                color: #A9AEB8;
            }
        }
        .avatar-container{  // 头像部分
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            .avatar-text{  
                margin: 0;
            }
            .img-container{  // 更换头像外层包裹
                position: relative;
                cursor: pointer;
                .avatar-img{  // 原来的头像
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .avatar-img-cover{  // 移动到头像上时显示照相机照片
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }
                .avatar-img-cover:hover{  // 移动到原来头像上时显示遮罩层
                    opacity: 1;
                }
            }
        }
        .el-form-item{
            margin-bottom: 24px;
        }
        /deep/ .el-form-item__label{  // label和输入框之间的间隔
            padding-right: 16px;
        }
        /deep/ .short-input{  // 短的输入框
            // width: 128px;
            width: 150px;
        }
        /deep/ .long-input{  // 长的输入框
            width: 384px;
        }
        .apply-school {
            color: #4E82E9;
            cursor: pointer;
        }
        .phone-container{
            margin-bottom: 32px;
        }
        .save-container{ // 保存按钮
            display: flex;
            align-items: flex-end;
            .save-text{  // 保存按钮右侧文字
                color: #2DA718;
                font-size: 12px;
                height: 32px;
                margin-left: 16px;
            }
        }
    }
}

.item-wrap {
    display: flex;
    align-items: flex-start;

    ::v-deep .verify-code {
        margin-left: 10px;
        transform: translateY(-2px);
    }
}
</style>