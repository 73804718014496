<template>
  <div class="cell-input-wrap">
    <el-input
      size="small"
      v-for="(item,index) in valList"
      :key="index"
      @keydown.native="onKeyDown($event, index)"
      @focus="onFocus($event)"
      @input="(val)=>{onInput(val, index)}"
      class="cell-input-show-item"
      :ref="'input'+ index"
      v-model="item.value"
    ></el-input>
  </div>
</template>
<script>
import { debounce } from "@/assets/js/tool.js"
export default {
  components: {},
  data() {
    return {
      valList: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
      refList: [],
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    onKeyDown(e, index) {
      let value = this.valList[index].value;
      const keyCode = e.keyCode;
      if (keyCode == 8) {
        if (value != "") {
          // 删除操作
          this.valList[index].value = "";
        } else {
          this.onInput("", index);
        }
        e.preventDefault();
        
        if(this.isEmpty()){
          this.onInputFull()
        }
        return false;
      }
    },
    onInput(value, index) {
      // 如果当前值为空 按下删除键
      if (value == "") {
        this.refList[index - 1] && this.refList[index - 1].focus();
      } else {
        let tempArr = value.split("");
        this.valList[index].value = tempArr[tempArr.length - 1];

        let next = this.refList[index + 1];
        if (next) {
          next.focus();
        } 
        if(this.isFull()){
          this.onInputFull()
        }
      }
    },
    isFull (){
      console.log("满了");
      let _code = []
      this.valList.forEach(item=>{
        if(item.value && item.value != ""){
          _code.push(item.value)
        }
      })
      // 全部输入
      if(_code.length == 6){
        return true
      }
      return false
    },
    isEmpty (){
      console.log("空了");
      let _code = []
      this.valList.forEach(item=>{
        if(!item.value || item.value == ""){
          _code.push(item.value)
        }
      })
      // 全部输入
      if(_code.length == 6){
        return true
      }
      return false
    },
    onInputFull: debounce(function(){
      this.$emit("onInputFull",this.valList)
    }, 600),
    clear(){
      this.valList.forEach(item=>{
        item.value = ""
      })
    },
    onFocus(e) {
      let obj = e.srcElement;
      obj.focus();
      const len = obj.value.length;
      // 光标定位要加上 setTimeOut，不然就会重新光标定位失败
      setTimeout(() => {
        obj.selectionStart = obj.selectionEnd = len;
      }, 20);
    },
  },
  mounted() {
    this.refList = []
    this.valList.forEach((item,index)=>{
      this.refList.push(...this.$refs['input' + index])
    })
  },
};
</script>
<style lang="scss" scoped >
.cell-input-wrap {
  display: inline-block;
  width: 232px;
  .cell-input-show-item {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin-right: 8px;
    /deep/ .el-input__inner {
      padding: 0;
      text-align: center;
    }
  }
  .cell-input-show-item:last-child {
    margin-right: 0;
  }
}
</style>