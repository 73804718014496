<template>
<div class="container">
    <div class="title">
        <div class="tab-container">
            <div>
                <span :class="{'tab':true , 'tab-active':tabIndex == 1}" @click="clickTab(1)" >教学效果</span>
                <span :class="{'tab':true , 'tab-active':tabIndex == 2}" @click="clickTab(2)">生活表现</span>
            </div>
            <div class="tip">您可以拖拽“姓名”进行编辑、创建自己的分组</div>
        </div>
        <div :class="{'tab-line':true, 'tab-line-life':isLife}" ></div>
    </div>
    <el-divider class="line"></el-divider>
    <keep-alive>
        <!-- 教学效果 -->
        <teaching-effect v-if="tabIndex == 1" :stuGroupList="stuGroupList" :selectedClass="selectedClass" :isHeadTeacher="isHeadTeacher"></teaching-effect>
        <!-- 生活效果 -->
        <life-performance v-if="tabIndex == 2" :selectedClass="selectedClass"></life-performance>
    </keep-alive>
</div>
</template>

<script>
import '@/styles/element-variables.scss';   // element-ui主题色
import TeachingEffect from '../ClassManagement/Widgets/TeachingEffect.vue'   // 教学效果
import LifePerformance from '../ClassManagement/Widgets/LifePerformance.vue'   // 生活效果
export default {
    components:{TeachingEffect, LifePerformance},
    props:['stuGroupList','selectedClass','isHeadTeacher'],
    data() {
        return {
            // selectedGrade:'三年级',      // 选择的年级
            // selectedClass:'二班',        // 选择的班级
            // className: 'PPD测试2班',      // 班级名称
            // editClassNameToggle:false,   // 是否编辑班级名称状态转换
            // creator:'海明威',             // 创建者
            // headTeacher:'海明威',         // 班主任
            isArrowUp:true,              // 修改科目右侧上下图标
            isSubjectClicked:null,        // 所点击的是哪个学科下标
            isLife:false,                 // 所点击的是不是生活表现
            tabIndex:1                    // 所点击的是哪个标签
        }
    },
    methods:{
        changeClassName(){  // 更改班级名称
            this.editClassNameToggle = false
        },
        editSubjectArea(){  // 修改科目区域的打开和关闭
            this.isArrowUp = !this.isArrowUp
        },
        clickSubjectOption(index){  // 点击修改区域的选项
            this.isSubjectClicked = index
        },
        clickTab(index){  // 点击标签页
            this.tabIndex = index
            index == 2 ? this.isLife = true : this.isLife = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/account-setting.scss';   // 颜色统一定义
.container{
    background-color: $bg-color2;
    min-height: 357px;
    border-radius: 4px;
    .title{   // 标题
        padding-top: 24px;
        padding-left: 32px;
        padding-right: 32px;
        font-size: 20px;
        font-weight: 600;
        .tab-container{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .tab{  // 标签页
                cursor: pointer;
                font-size: 16px;
            }
            .tab-active{  // 点击某个标签页时
                font-size: 20px;
            }
            .tip{  // 提示文字
                display: inline-block;
                width: 241px;
                height: 17px;
                font-size: 12px;
                color: #A9AEB8;
            }
        }
        .tab-line{   // 教学效果和生活表现下面的跟随条
            width: 48px;
            height: 3px;
            background-color: #FF7513;
            position: relative;
            top: 15px;
            left: 15px;
            transition: 0.3s;
        }   // 切换到生活表现时
        .tab-line-life{
            transform:translate(97px)
        }
    }
    .title span{
        margin-right: 32px;
    }
    .line{   // 分割线
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .content{ // 内容区
        padding: 24px 32px 32px 32px;
        .select-class{  // 任教下面的 选择年级班级区域
            display: flex;
            align-items: center;
            .teach-other-class{  // 任教其他班级
                display: flex;
                width: 128px;
                height: 30px;
                border: 1px solid #FF7513;
                font-size: 14px;
                border-radius: 4px;
                cursor: pointer;
                align-items: center;
                color: #FF7513;
                background: rgba(255,117,19,0.04);
                .add-class-icon{
                    width: 16px;
                    height: 16px;
                    margin-left: 12px;
                    margin-right: 4px;
                }
            }
        }
        .setting-info{  // 信息展示的区域
            margin-top: 32px;
            .row-info{  // 每一行信息
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-bottom: 24px;
                .row-title{  // 每一行左侧的标题
                    width: 56px;
                    height: 22px;
                    margin-right: 16px;
                    background-color: antiquewhite;
                    text-align: end;
                }
                .setting-content{  // 班级名称
                    margin-right: 16px;
                    height: 22px;
                }
                .setting-content-edit{
                    height: 22px;
                    line-height: 22px;
                    width: 150px;
                    margin-right: 16px;
                    ::v-deep .el-input__inner{  //修改班级名称
                        width: 150px;
                        height: 22px;
                    }
                }
                .change-name{  // 改名
                    font-size: 12px;
                    color: #FF7513;
                    cursor: pointer;
                    height: 22px;
                    line-height: 22px;
                }
                .resign{  // 班主任-辞任
                    color:#FF3B30;
                    font-size: 12px;
                    height: 22px;
                    line-height: 22px;
                    cursor: pointer;
                }
                .subject-area{
                    display: flex;
                    flex:1;
                    flex-wrap: wrap;
                    .change-subject{  // 修改任教科目
                        height: 22px;
                        display: flex;
                        font-size: 12px;
                        color: #FF7513;
                        align-items: center;
                        cursor: pointer;
                        .arrow-down{  // 修改科目右侧三角图标  箭头向下
                            width: 12px;
                            height: 12px;
                            margin-left: 2px;
                            transition: 0.3s;
                        }
                        .arrow-up{  //箭头向上
                            transform: rotate(-180deg);
                            transition: 0.3s;
                        }
                        .popover-arrow{ // 修改科目气泡框的三角形
                            position: relative;
                            content: '';
                            width: 0;
                            height: 0;
                            border-width: 10px;
                            border-style: solid;
                            border-color: transparent transparent #F2F3F5 transparent;
                            left: -50%;
                            top: 15px;
                        }
                    }
                }
            }
            .last-row-info{   // 任教科目的区域
                align-items: flex-start;
                margin-bottom: 10px;
            }
            .select-subject-area{  // 修改科目选择区
                height: 176px;
                background-color: #F2F3F5;
                border-radius: 4px;
                padding: 24px 7px 24px 24px;
                .option-container{
                    height: 100%;
                    background-color: #F2F3F5;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: baseline;
                    overflow-y: auto;
                    .subject-option{  // 修改学科下面的每一项
                        width: 15%;
                        height: 32px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .subject-option:not(:nth-child(6n)){  // 每一行最右面一项不显示margin-right
                        margin-right: calc(2%);
                    }
                    .subject-option:hover{  // 悬浮时状态
                        color: #FF7513;
                    }
                    .subject-clicked{  // 选项被点击的时候
                        background-color: #FF7513;
                        color:#FFFFFF !important
                    }
                }
            }
        }
    }
}
</style>